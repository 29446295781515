import React, { useState, useContext, useEffect } from "react";
import { FaArrowRight } from "react-icons/fa";
import { Button } from "@windmill/react-ui";
import { useModalAction, useModalState } from "../../context/modalContext";
import { useMutation } from "urql";
import { SubscribeMutation } from "../../Queries";
import { AuthContext } from "../../context/AuthContext";
import { useToasts } from "react-toast-notifications";

import { ethers } from "ethers";
import subscription_Abi from "../../subscription_Abi.json";

const SubscribePopup = () => {
  const [subsETH, setSubsETH] = useState(0);
  const [subsUSD, setSubsUSD] = useState(0);
  const [subsPeriod, setSubsPeriod] = useState(0);

  const [terms, setTerms] = useState(false);
  const { data } = useModalState();
  const { openModal, closeModal } = useModalAction();
  const { auth } = useContext(AuthContext);

  const { addToast } = useToasts();

  console.log(data, "data");
  const [{ fetching }, executeMutation] = useMutation(SubscribeMutation);

  const getSubsValues = async () => {
    try {
      if (
        typeof window.ethereum !== "undefined" &&
        window.ethereum.networkVersion === process.env.REACT_APP_CHAINID
      ) {
        const provider = new ethers.providers.Web3Provider(window?.ethereum);
        const signer = provider.getSigner();
        const addr = await signer.getAddress();

        const contractAddress = process.env.REACT_APP_SUBSCRIPTION_ADDRESS;
        const contract = new ethers.Contract(
          contractAddress,
          subscription_Abi,
          signer
        );
        const subsFee = await contract.getSubscriptionFee();
        setSubsETH(subsFee);

        const subsUSD = await contract.subscriptionFeeUSD();
        setSubsUSD(subsUSD);

        const subsPeriod = await contract.subscriptionPeriod();
        setSubsPeriod(subsPeriod);
      } else {
        throw "Wrong network! Please connect to the correct network.";
      }
    } catch (error) {
      // console.log(error);
      if (error.toString().includes("unknown account #0")) {
        addToast("Please connect to wallet first!", { appearance: "error" });
      } else {
        addToast(error?.reason || error, { appearance: "error" });
      }
    }
  };

  useEffect(() => {
    getSubsValues();
  }, []);

  const handleCheckout = async () => {
    console.log(auth?.userId, "auth", data);
    const subscribe = await executeMutation({
      creator_id: data?.id,
      subscriber_id: auth?.userId,
    });
    console.log(subscribe, "subscribe");
    if (subscribe?.data?.insert_subscriptions_one?.id) {
      closeModal("CHECKOUT_VIEW");
      console.log(subscribe);
      openModal("SUCCESS_VIEW");
      window.location.reload();
    }
  };

  const startSubscription = async () => {
    try {
      if (
        typeof window.ethereum !== "undefined" &&
        window.ethereum.networkVersion == process.env.REACT_APP_CHAINID
      ) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const addr = await signer.getAddress();

        const contractAddress = process.env.REACT_APP_SUBSCRIPTION_ADDRESS;
        const contract = new ethers.Contract(
          contractAddress,
          subscription_Abi,
          signer
        );
        const subsFee = await contract.getSubscriptionFee();
        // console.log("Subs fee: ", subsFee.toString());

        console.log("Artist address: ", data.wallet_id);
        const tx = await contract.startSubscription(
          data?.wallet_id?.toString(),
          { value: subsFee.toString() }
        ); //need to pass artist address
        const receipt = await tx.wait();
        // console.log(receipt);

        handleCheckout();
      } else {
        throw "Wrong network! Please connect to the correct network.";
      }
    } catch (error) {
      // console.log(error);
      if (error.toString().includes("unknown account #0")) {
        addToast("Please connect to wallet first!", { appearance: "error" });
      } else {
        addToast(error?.reason || error, { appearance: "error" });
      }
    }
  };
  return (
    <div>
      <h3 className="text-lg font-semibold dark:text-white">Subscribe</h3>

      <div className="grid grid-cols-6 gap-4 mb-6 mt-4">
        <div className="col-span-4">
          <h5 className="text-lg mb-4 font-medium">Creator</h5>
          <div className="flex items-center">
            <div className="nftInfo ml-4">
              {/* <h6 className="mb-2">{data.wallet_id}</h6> */}
              <h3 className="text-xl font-medium">{data?.username}</h3>
            </div>
          </div>
        </div>
        <div className="col-span-2 text-right">
          <h5 className="text-lg mb-4 font-medium">Subtotal</h5>
          <h4 className="text-lg font-medium mb-2">{`${Number(
            ethers.utils.formatEther(subsETH)
          ).toFixed(5)} ETH`}</h4>
          <p className="text-sm">{`$${subsUSD}`}</p>
        </div>
      </div>
      <div className="bg-gray-100 p-4">
        <p className="text-lg">
          Duration of subscription: {`${subsPeriod}`} days
        </p>
      </div>
      <div className="flex items-center mt-4">
        <input
          type="checkbox"
          className="cursor-pointer"
          checked={terms}
          onChange={() => setTerms((prevState) => !prevState)}
        />
        <span className="text-xs text-gray-600 ml-2">
          By checking this box, I agree to Gruve’s Terms of Service
        </span>
      </div>
      <div className="text-right mt-6">
        <Button
          disabled={!terms}
          onClick={() => {
            startSubscription();
          }}
          className="bg-purple-600 ml-auto py-3 px-12 main-button  text-white flex items-center"
        >
          <span>Checkout</span>
          <FaArrowRight className="ml-6" />
        </Button>
      </div>
    </div>
  );
};

export default SubscribePopup;
