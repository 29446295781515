import React from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

function Collapsible({ Icon, iconClass, title, open, setOpen, children }) {
  return (
    <div>
      <div className="flex items-center p-4 mb-1 bg-white dark:bg-cool-gray-800 rounded-lg border dark:border-cool-gray-600">
        {Icon && (
          <Icon
            className={`w-6 h-6 text-black dark:text-white ${iconClass ?? ""}`}
          />
        )}
        <h5 className="ml-4 font-semibold dark:text-white">{title}</h5>
        <button
          className="ml-auto"
          onClick={() => setOpen((prevState) => !prevState)}
        >
          {open ? (
            <FaChevronUp className="w-4 h-4 dark:text-white" />
          ) : (
            <FaChevronDown className="w-4 h-4 dark:text-white" />
          )}
        </button>
      </div>
      {open && (
        <div className="px-6 py-8 bg-white dark:bg-cool-gray-800 rounded-lg dark:text-white">
          {children}
        </div>
      )}
    </div>
  );
}

export default Collapsible;
