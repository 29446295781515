import React, { Component, useEffect, useState } from "react";
import { render } from "react-dom";
import SlidingPane from "react-sliding-pane";
import { FaBell, FaArrowRight } from "react-icons/fa";
import { useQuery, useMutation } from "urql";
import {
  GetNotifications,
  UpdateNotificationState,
  DeleteNotifications,
} from "../../Queries";
import ReactPaginate from "react-paginate";
import NotificationCard from "./NotificationCard";
import { useToasts } from "react-toast-notifications";
import { ReactComponent as Bell } from "../../icons/bellnew.svg";

export default function Notification({ theme }) {
  const limit = 8;
  const { addToast } = useToasts();
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [isNew, setNew] = useState(false);
  const [pageCount, setpageCount] = useState(0);
  const [updateNotification, executeUpdate] = useMutation(
    UpdateNotificationState
  );
  const [deleteNotification, executeDelete] = useMutation(DeleteNotifications);
  const clearList = async () => {
    try {
      const del = await executeDelete();
      if (del?.data?.delete_notifications?.affected_rows) {
        addToast("Notifications Cleared", { appearance: "success" });
      }
    } catch (e) {
      console.log(e);
      addToast("Couldn't Clear the List", { appearance: "error" });
    }
  };
  const [searchQuery, executeSearch] = useQuery({
    query: GetNotifications,
    variables: {
      offset: page * limit,
      limit,
      limit: limit,
    },
    pause: true,
  });

  const { data: searchData, fetching: searchFetching } = searchQuery;
  useEffect(() => {
    if (searchData?.notifications_aggregate?.aggregate?.count) {
      setpageCount(searchData?.notifications_aggregate?.aggregate?.count / 10);
    }
    if (searchData?.countNew?.aggregate?.count > 0) {
      setNew(true);
    } else {
      setNew(false);
    }
  }, [searchData]);
  useEffect(() => {
    executeSearch({ requestPolicy: "network-only" });
  }, [
    page,
    updateNotification?.data?.update_notifications_by_pk?.id,
    deleteNotification?.data?.delete_notifications?.affected_rows,
  ]);
  return (
    <div className="relative top-0">
      <div>
        <Bell
          style={{
            stroke: theme === "dark" ? "#fff" : "#000",
            ...(isNew ? { stroke: "#fbbf24" } : {}),
          }}
          className={`cursor-pointer ${notificationOpen && "hidden"}`}
          onClick={(e) => {
            e.preventDefault();
            setNotificationOpen(true);
          }}
        />
      </div>

      <SlidingPane
        className={`dark:bg-gray-800  bg-gray-50 dark:text-gray-50 notif${
          theme === "light" ? "slide-pane-light" : "slide-pane-dark"
        } `}
        closeIcon={
          <div>
            <FaArrowRight className={`text-black dark:text-white`} />
          </div>
        }
        isOpen={notificationOpen}
        title="Notifications"
        from="right"
        width="350px"
        shouldCloseOnEsc
        overlayClassName=""
        onRequestClose={() => setNotificationOpen(false)}
      >
        <div className="w-full ">
          {searchData?.notifications?.length > 0 ? (
            <div className="w-full">
              <div
                onClick={(e) => {
                  e.preventDefault();
                  clearList();
                }}
                className="underline mb-1 text-sm cursor-pointer flex justify-end"
              >
                {" "}
                clear list
              </div>
              {searchData?.notifications?.map((notification) => (
                <NotificationCard
                  notification={notification}
                  key={notification?.id}
                  executeUpdate={executeUpdate}
                />
              ))}
              <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                onPageChange={(s) => {
                  setPage(s?.selected);
                }}
                activeClassName="dark:text-gray-400 text-gray-600 text-xs font-semibold"
                disabledLinkClassName="cursor-not-allowed dark:text-gray-600 text-xs text-gray-400"
                disabledClassName="cursor-not-allowed dark:text-gray-600 text-gray-400 text-xs"
                className="justify-center mt-3 text-xs dark:text-gray-500 text-gray-500  capitalize font-semibold  space-x-4 flex items-center m-2"
                pageRangeDisplayed={limit}
                pageCount={Math.ceil(pageCount)}
                previousLabel="< "
                renderOnZeroPageCount={null}
              />
            </div>
          ) : (
            <div className="text-sm text-center">It's Empty Here :</div>
          )}
        </div>
      </SlidingPane>
    </div>
  );
}
