import React, { createContext, useContext } from "react";
import { ThemeContext } from "./ThemeContext";
import {
  WagmiConfig,
  createClient as client_wagmi,
  configureChains,
  chain,
} from "wagmi";
import { publicProvider } from "wagmi/providers/public";
import "@rainbow-me/rainbowkit/styles.css";
import {
  getDefaultWallets,
  RainbowKitProvider,
  darkTheme,
  lightTheme,
  Theme,
} from "@rainbow-me/rainbowkit";
import merge from "lodash.merge";

const { chains, provider, webSocketProvider } = configureChains(
  [chain.mainnet],
  [publicProvider()]
);
const { connectors } = getDefaultWallets({
  appName: "Gruve",
  chains,
});

const clientWagmi = client_wagmi({
  autoConnect: true,
  connectors: connectors,
  provider,
  webSocketProvider,
});
export const WagmiContext = createContext({});

export const WagmiProvider = ({ children }) => {
  const { theme } = useContext(ThemeContext);
  const value = {};
  const myTheme = merge(theme === "dark" ? darkTheme() : lightTheme(), {
    colors: {
      connectButtonBackground: "#1f2937",
      connectButtonText: "#fff",
      actionButtonBorder: "#1f2937",
      generalBorder: "#1f2937",
      connectButtonInnerBackground: "#1f2937",
    },
  });
  return (
    <WagmiContext.Provider value={value}>
      <WagmiConfig client={clientWagmi}>
        <RainbowKitProvider theme={myTheme} chains={chains}>
          {children}
        </RainbowKitProvider>
      </WagmiConfig>
    </WagmiContext.Provider>
  );
};
