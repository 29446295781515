

const myAccountItems = [
  {
    name: "My Profile",
    path: "/profile/view/activity",
  },
  {
    name: "My Subscription",
    path: "subscriptions",
  },
  {
    name: "My Favorites",
    path: "/profile/view/favorite",
  },
  {
    name: "My Account Settings",
    path: "/profile/settings",
  },
  {
    name: "My Wallet",
    path: "/profile/view/collected",
  },
];

const resourceItems = [
  {
    name: "Help Center",
    path: "https://gruve.gitbook.io/about-gruve/",
  },
  // {
  //   name: "Discord ",
  //   path: "https://discord.gg/7wMnBP2APQ",
  // },
  {
    name: "Twitter",
    path: "https://twitter.com/Gruve_one",
  },
  {
    name: "Instagram",
    path: "https://www.instagram.com/gruve.one/ ",
  },
  { name: "Mirror", path: "https://mirror.xyz/0x89DE49E18F8398BefC928682c8DCcddB30Fae7A8" },
];

const companyItems = [
  {
    name: "About Us",
    path: "https://gruve.gitbook.io/about-gruve/information/about-us",
  },
];

export { companyItems, myAccountItems, resourceItems };
