import React, { lazy, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AccessibleNavigationAnnouncer from "./components/AccessibleNavigationAnnouncer";
import packageJson from "../package.json";
import PrivateRoute from "./privateRoute";
import { routes, privateRoutes } from "./routes";
import { ModalProvider } from "./context/modalContext";
import ManagedModal from "./components/modal/managedModal";

global.appVersion = packageJson.version;
const Layout = lazy(() => import("./containers/Layout"));

const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

function App() {
  useEffect(() => {
    fetch("/meta.json")
      .then((response) => response.json())
      .then((meta) => {
        const latestVersion = meta.version;
        const currentVersion = global.appVersion;

        const shouldForceRefresh = semverGreaterThan(
          latestVersion,
          currentVersion
        );
        if (shouldForceRefresh) {
          console.log(
            `We have a new version - ${latestVersion}. Should force refresh`
          );

          if (caches) {
            // Service worker cache should be cleared with caches.delete()
            console.log("Invalidating cache..!");
            caches.keys().then(function (names) {
              for (let name of names) caches.delete(name);
            });
          } // delete browser cache and hard reload
          // window.location.reload(true);
          window.location.reload();
          // setLoading(false);
          // setLatestVersion(false);
        } else {
          console.log(
            `You already have the latest version - ${latestVersion}. No cache refresh needed.`
          );
          // setLoading(false);
          // setLatestVersion(true);
        }
      }, []);
  });

  return (
    <>
      <Router>
        <AccessibleNavigationAnnouncer />
        {/* <scrollToTop /> */}
        <ModalProvider>
          <ManagedModal />
          <Routes>
            {privateRoutes.map((route, idx) => {
              return (
                <Route
                  path={route.path}
                  key={idx}
                  element={
                    <PrivateRoute
                      path={route.path}
                      key={idx}
                      children={route.component}
                    />
                    // <PrivateRoute path={route.path} key={idx}>
                    //   {/* <Layout children={route.component} /> */}
                    // </PrivateRoute>
                  }
                />
              );
            })}{" "}
            {routes.map((route, idx) => {
              return (
                <Route
                  path={route.path}
                  element={<Layout children={route.component} />}
                  key={idx}
                />
              );
            })}
          </Routes>
        </ModalProvider>
      </Router>
    </>
  );
}

export default App;
