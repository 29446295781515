import React, { useState, useContext, useEffect } from "react";
import { Button } from "@windmill/react-ui";
import { useModalAction, useModalState } from "../../context/modalContext";
import { AuthContext } from "../../context/AuthContext";
import { useToasts } from "react-toast-notifications";
import Countdown, { CountdownApi } from "react-countdown";

const OfferPopup = () => {
  const [terms, setTerms] = useState(false);
  const { data } = useModalState();
  const { openModal, closeModal } = useModalAction();
  const { auth } = useContext(AuthContext);
  const { addToast } = useToasts();

  const handleOffer = async () => {
    try {
      addToast("success", { appearance: "success" });
    } catch (e) {
      addToast("error", { appearance: "error" });
    }
  };

  return (
    <div>
      <div className="flex justify-center">
        <div>
          <h5 className="text-lg text-gray-900  dark:text-gray-100  text-center mb-4 font-bold">
            Offer On {data?.nft?.name}
          </h5>
          <h5 className="text-lg text-gray-900  dark:text-gray-100  text-center font-medium">
            {data?.price} ETH
          </h5>
          <div className="text-gray-900 text-center dark:text-gray-100  text-sm mt-1">
            <Countdown
              renderer={(props) => (
                <div>
                  {props?.completed ? (
                    <div>SaLe Ended</div>
                  ) : (
                    <div>
                      <div className="flex space-x-4">
                        {props?.days > 0 && (
                          <div className="text-black text-sm  dark:text-gray-50">
                            {props?.days}{" "}
                            <span className="text-base font-sm">D</span>
                          </div>
                        )}
                        {props?.hours > 0 && (
                          <div className="text-black textsm  dark:text-gray-50">
                            {props?.hours}{" "}
                            <span className="text-base font-sm">H</span>
                          </div>
                        )}{" "}
                        {props?.minutes > 0 && (
                          <div className="text-black text-sm dark:text-gray-50">
                            {props?.minutes}{" "}
                            <span className="text-base font-sm">M</span>
                          </div>
                        )}
                        {props?.seconds > 0 && (
                          <div className="text-black text-sm dark:text-gray-50">
                            {props?.seconds}{" "}
                            <span className="text-base font-sm">S</span>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
              date={new Date(data?.created_at).setFullYear(2034)}
            />{" "}
          </div>{" "}
        </div>
      </div>

      <div className="text-right mt-6 grid-cols-2 grid gap-4 ">
        <button
          onClick={(e) => {
            e.preventDefault();
            closeModal();
          }}
          className=" grid   py-3 px-12 border-purple-600 border rounded-lg shadow-xl text-purple-600 dark:text-white items-center"
        >
          <span>Decline</span>
        </button>
        <Button
          onClick={handleOffer}
          className="bg-purple-600 grid  py-3 px-12 rounded-lg shadow-xl text-white  items-center"
        >
          <span>Accept</span>
        </Button>
      </div>
    </div>
  );
};

export default OfferPopup;
