import React, { useContext, useEffect, useState } from "react";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { ethers } from "ethers";
import { AuthContext } from "../../context/AuthContext";
import { useDisconnect } from "wagmi";
import { useNavigate } from "react-router-dom";
import { ReactComponent as User } from "../../icons/user.svg";
import { ReactComponent as Favorite } from "../../icons/heartnew.svg";
import { ReactComponent as Feed } from "../../icons/feed.svg";
import { ReactComponent as Subscribe } from "../../icons/subscription.svg";
import { ReactComponent as Settings } from "../../icons/settingsnew.svg";
import { ReactComponent as Logout } from "../../icons/logout.svg";
import { ThemeContext } from "../../context/ThemeContext";
import { ReactComponent as MoonIcon } from "../../icons/moonnew.svg";
import { ReactComponent as SunIcon } from "../../icons/sunnew.svg";

export default function ButtonConnect() {
  const [balance, setBalance] = useState(0);
  const [showDropdown, setShowDropdown] = useState(false);
  const { auth } = useContext(AuthContext);
  const { disconnect } = useDisconnect();
  const navigate = useNavigate();
  const { theme, toggleTheme } = useContext(ThemeContext);

  const getBalance = async () => {
    try {
      const provider = new ethers.providers.Web3Provider(window?.ethereum);
      const signer = provider?.getSigner();
      // const bal = await signer?.getBalance();
      // setBalance(parseFloat(ethers?.utils?.formatEther(bal)).toFixed(4));
      const avatar = await provider?.getAvatar(auth?.walletId);
    } catch (e) {
      console.log(e);
      return e;
    }
  };
  const checkConnection = async () => {
    try {
      if (
        typeof window.ethereum !== "undefined" &&
        window.ethereum.networkVersion === process.env.REACT_APP_CHAINID
      ) {
        await getBalance();
      } else {
        console.log("connect metamask");
      }
    } catch (e) {
      console.log("Error while get balance", e);
    }
  };
  useEffect(() => {
    checkConnection();
  }, [window.ethereum]);

  const generateColorFromAddress = (address) => {
    let hash = 0;
    for (let i = 0; i < address.length; i++) {
      hash = address.charCodeAt(i) + ((hash << 5) - hash);
    }
    const color = `hsl(${hash % 360}, 50%, 50%)`;
    return color;
  };

  const toggleDropdown = () => setShowDropdown(!showDropdown);

  // Handlers for new dropdown options
  const handleProfileClick = () => {
    navigate("/profile/view/collected");
    console.log("My Profile clicked");
    // Implement navigation or functionality
  };

  const handleFavoritesClick = () => {
    navigate("/profile/view/favorite");
    console.log("Favorites clicked");
    // Implement navigation or functionality
  };

  const handleSettingsClick = () => {
    navigate("/profile/settings");
    console.log("Settings clicked");
    // Implement navigation or functionality
  };

  const handleFeedClick = () => {
    navigate("/feeds");
    console.log("Feed clicked");
    // Implement navigation or functionality
  };

  const handleSubscriptionClick = () => {
    navigate("/subscriptions");
    console.log("Subscriptions clicked");
    // Implement navigation or functionality
  };

  const getDropdownItemClassName = () =>
    theme === "dark" ? "dropdownDark-item" : "dropdown-item";
  const getIconStrokeColor = () => (theme === "dark" ? "white" : "black");

  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        const ready = mounted && authenticationStatus !== "loading";
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus || authenticationStatus === "authenticated");
        const isGoerliNetwork = process.env.REACT_APP_CHAINID; // Check if the chain ID is 5 (Goerli Testnet)
        return (
          <div
            {...(!ready && {
              "aria-hidden": true,
              style: { opacity: 0, pointerEvents: "none", userSelect: "none" },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <div
                    className="text-black dark:text-white"
                    onClick={openConnectModal}
                    style={{
                      cursor: "pointer",
                      borderRadius: "5px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                    }}
                  >
                    Log In
                  </div>
                );
              }
              if (!isGoerliNetwork) {
                return (
                  <div
                    onClick={openChainModal}
                    style={{ cursor: "pointer", color: "red" }}
                  >
                    Wrong Network
                  </div>
                );
              } else {
                return (
                  <div
                    style={{
                      display: "flex",
                      gap: 12,
                      alignItems: "center",
                      position: "relative",
                      borderRadius: "5px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                    }}
                  >
                    <div
                      onClick={toggleDropdown}
                      style={{
                        backgroundColor: generateColorFromAddress(
                          account?.address
                        ),
                        width: 35,
                        height: 35,
                        borderRadius: "50%",
                        cursor: "pointer",
                      }}
                      className="dropdown-animation border border-1 border-black dark:border-white"
                      title="Account options"
                    ></div>
                    <span className="text-black dark:text-white">Menu</span>
                    {showDropdown && (
                      <div
                        className={`dropdown-menu ${
                          showDropdown ? "show-dropdown" : ""
                        } ${
                          theme === "dark" ? "dropdownDark" : "dropdown-menu"
                        }`}
                      >
                        <div
                          onClick={handleProfileClick}
                          className={getDropdownItemClassName()}
                        >
                          <User
                            className="menu-icon"
                            stroke={getIconStrokeColor()}
                          />{" "}
                          My Profile
                        </div>
                        <div
                          onClick={handleFavoritesClick}
                          className={getDropdownItemClassName()}
                        >
                          <Favorite
                            className="menu-icon"
                            stroke={getIconStrokeColor()}
                          />{" "}
                          Favorites
                        </div>
                        <div
                          onClick={handleFeedClick}
                          className={getDropdownItemClassName()}
                        >
                          <Feed
                            className="menu-icon"
                            stroke={getIconStrokeColor()}
                          />{" "}
                          Feed
                        </div>
                        <div
                          onClick={handleSubscriptionClick}
                          className={getDropdownItemClassName()}
                        >
                          <Subscribe
                            className="menu-icon"
                            stroke={getIconStrokeColor()}
                          />{" "}
                          Subscriptions
                        </div>
                        <div
                          onClick={handleSettingsClick}
                          className={getDropdownItemClassName()}
                        >
                          <Settings
                            className="menu-icon"
                            stroke={getIconStrokeColor()}
                          />{" "}
                          Settings
                        </div>
                        <div
                          onClick={toggleTheme}
                          className={getDropdownItemClassName()}
                          aria-label="Toggle color mode"
                        >
                          {theme === "dark" ? (
                            <>
                              <SunIcon
                                className="menu-icon"
                                stroke={getIconStrokeColor()}
                              />
                              <span>Light Mode</span>
                            </>
                          ) : (
                            <>
                              <MoonIcon
                                className="menu-icon"
                                stroke={getIconStrokeColor()}
                              />
                              <span>Dark Mode</span>
                            </>
                          )}
                        </div>
                        <div
                          onClick={() => disconnect()}
                          className={getDropdownItemClassName()}
                        >
                          <Logout
                            className="menu-icon"
                            stroke={getIconStrokeColor()}
                          />{" "}
                          Log Out
                        </div>
                      </div>
                    )}
                  </div>
                );
              }
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
}
