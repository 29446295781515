import React, { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { HiCheck } from "react-icons/hi";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

export default function CustomSelectBox({
  options,
  selectedItem,
  setSelectedItem,
  label,
  className,
  placeholder,
}) {
  function handleItemClick(values) {
    setSelectedItem(values);
  }

  return (
    <div>
      {label && (
        <label
          htmlFor={label}
          className="block font-medium text-sm leading-none mb-3 cursor-pointer text-skin-base text-opacity-70 dark:text-white"
        >
          {label}
        </label>
      )}
      <Listbox value={selectedItem} onChange={handleItemClick}>
        {({ open }) => (
          <div className="relative">
            <Listbox.Button
              className={`font-medium text-sm w-full text-left p-3 focus:outline-none pr-10 ${className}`}
            >
              <span className="block truncate">
                {selectedItem ? selectedItem?.name : placeholder}
              </span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                {open ? (
                  <FiChevronUp aria-hidden="true" />
                ) : (
                  <FiChevronDown aria-hidden="true" />
                )}
              </span>
            </Listbox.Button>
            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                static
                className="absolute w-full py-1 mt-1 overflow-auto bg-white dark:bg-gray-700 rounded-md shadow-xl focus:outline-none text-sm z-40"
              >
                {options?.map((option, personIdx) => (
                  <Listbox.Option
                    key={personIdx}
                    className={({ active }) =>
                      `${
                        active
                          ? "text-yellow-400"
                          : "text-gray-900 dark:text-white"
                      }
                          cursor-pointer select-none relative py-2 pl-10 pr-4 text-left`
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`${
                            selected ? "font-medium" : "font-normal"
                          } block truncate capitalize`}
                        >
                          {option?.name}
                        </span>
                        {selected ? (
                          <span
                            className={`${active ? "text-amber-600" : ""}
                                check-icon absolute inset-y-0 left-0 flex items-center pl-3`}
                          >
                            <HiCheck aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        )}
      </Listbox>
    </div>
  );
}
