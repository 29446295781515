import React, { useContext, useState } from "react";
import { SidebarContext } from "../context/SidebarContext";
import { SearchIcon, MenuIcon } from "../icons";
import { Input, Dropdown, DropdownItem } from "@windmill/react-ui";
import { HEADER_ROUTES } from "../routes/header";
import { NavLink } from "react-router-dom";
import MobileSidebar from "./Sidebar/MobileSidebar";
import Logo from "./Logo";
import { useLocation } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

import { ThemeContext } from "../context/ThemeContext";
import ButtonConnect from "./Buttons/ConnectButton";
import { useAccount } from "wagmi";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import Notification from "./Notification/HeaderNotification";
import SearchInput from "./SearchInput";


function Header() {
  const { theme, toggleTheme } = useContext(ThemeContext);
  const { toggleSidebar } = useContext(SidebarContext);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const { pathname } = useLocation();
  const { auth } = useContext(AuthContext);
  const { isConnected } = useAccount();

  function handleProfileClick() {
    setIsProfileMenuOpen(!isProfileMenuOpen);
  }

  return (
    <header
      key={"header"}
      className=" px-6 lg:px-0 relative left-0 top-0 w-full bg-gray-50 dark:bg-gray-800 lg:bg-transparent lg:dark:bg-transparent mt-4"
    >
      <div className="flex items-center justify-between h-full text-purple-500">
        {/* <!-- Mobile hamburger --> */}
        <button
          className="p-1 mr-3 rounded-md lg:hidden focus:outline-none focus:shadow-outline-purple text-black dark:text-white"
          onClick={toggleSidebar}
          aria-label="Menu"
        >
          <MenuIcon className="w-6 h-6" aria-hidden="true" />
        </button>
        <MobileSidebar />
        {/* <!-- Search input --> */}
        <div className="lg:grid lg:grid-cols-12 w-full">
          <div className="flex items-center flex-1 lg:px-10 sm:pl-4 xl:pl-16 col-span-5">
            <Logo logoMarkClass="w-32" />

            <div className="relative w-full max-w-sm ml-4 lg:ml-12 py-2 flex-1">
              <div className="absolute inset-y-0 flex items-center pl-4">
                <SearchIcon
                  className="w-5 h-5 text-gray-900 dark:text-gray-300"
                  aria-hidden="true"
                />
              </div>
              <SearchInput key={"search input"} theme={theme} />
            </div>
          </div>

          <div className="hidden lg:block col-span-7 px-10 xl:pr-16">
            <ul className="flex z-10 header-items items-center gap-6 sm:space-x-1 xl:space-x-3">

              <li>
                <NavLink
                  to="/explore"
                  className="-mb-1 inline-flex items-center w-full font-normal transition-colors duration-150 text-black dark:text-white sm:text-xs xl:text-base py-5 border-b-4 border-transparent"
                  activeclassname="text-yellow-300 border-b-4 border-yellow-300"
                >
                  <span className="capitalize">Explore</span>
                </NavLink>
              </li>
              {isConnected && auth?.token && (
                <li>
                  <NavLink
                    to="/create"
                    className="-mb-1 inline-flex items-center w-full font-normal transition-colors duration-150 text-black dark:text-white sm:text-xs xl:text-base py-5 border-b-4 border-transparent"
                    activeclassname="text-yellow-300 border-b-4 border-yellow-300"
                  >
                    <span className="capitalize">create</span>
                  </NavLink>
                </li>
              )}
              {isConnected && auth?.token && (        
              <li className="z-20">
                <Notification theme={theme} className="fill-black" />
              </li> )}
              {/* connection button */}
              <li>
                <ButtonConnect />{" "}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
