import React, { useContext } from "react";
import { MoonIcon, SunIcon } from "../../icons";
import { Button, WindmillContext } from "@windmill/react-ui";
import routes from "../../routes/sidebar";
import { NavLink, Route, Routes } from "react-router-dom";
import * as Icons from "../../icons";
import SidebarSubmenu from "./SidebarSubmenu";
import { AuthContext } from "../../context/AuthContext";
import { SidebarContext } from "../../context/SidebarContext";
import { AiOutlineClose } from "react-icons/ai";

function Icon({ icon, ...props }) {
  const Icon = Icons[icon];
  return <Icon {...props} />;
}

function SidebarContent() {
  const { disconnectWallet } = useContext(AuthContext);
  const { mode, toggleMode } = useContext(WindmillContext);
  const { toggleSidebar } = useContext(SidebarContext);

  return (
    <div className="py-4 text-gray-500 dark:text-gray-400">
      <div className="flex items-center justify-between px-6">
        <a
          className="text-lg font-bold text-gray-800 dark:text-gray-200"
          href="#"
        >
          Gruve
        </a>
        <button onClick={toggleSidebar} aria-label="close-menu">
          <AiOutlineClose className="w-5 h-5" aria-hidden="true" />
        </button>
      </div>
      <ul className="mt-6">
        {routes.map((route) =>
          route.routes ? (
            <SidebarSubmenu route={route} key={route.name} />
          ) : (
            <li className="relative px-6 py-3" key={route.name}>
              <NavLink
                to={route.path}
                className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
                activeclassname="text-gray-800 dark:text-gray-100"
              >
                <Routes>
                  <Route
                    path={route.path}
                    element={
                      <span
                        className="absolute inset-y-0 left-0 w-1 bg-purple-600 rounded-tr-lg rounded-br-lg"
                        aria-hidden="true"
                      ></span>
                    }
                    exact={route.exact}
                  ></Route>
                </Routes>
                <Icon
                  className="w-5 h-5"
                  aria-hidden="true"
                  icon={route.icon}
                />
                <span className="ml-4">{route.name}</span>
              </NavLink>
            </li>
          )
        )}
      </ul>

      <div className="px-6 my-6">
        <Button
          onClick={() => {
            disconnectWallet();
          }}
        >
          Logout
        </Button>
        <Button
          layout="outline"
          onClick={toggleMode}
          aria-label="Toggle color mode"
          className="ml-4"
        >
          {mode === "dark" ? (
            <SunIcon className="w-5 h-5" aria-hidden="true" />
          ) : (
            <MoonIcon className="w-5 h-5" aria-hidden="true" />
          )}
        </Button>
      </div>
      <div className="px-2 pt-2 text-xs">App version: {global.appVersion}</div>
    </div>
  );
}

export default SidebarContent;
