import React from "react";
import LogoMark from "../assets/img/gruve-logo.svg";
import { NavLink } from "react-router-dom";

function Logo({ logoMarkClass }) {
  return (
    <NavLink  to="/" className="flex items-center">
      <img src={LogoMark} alt="logomark" className={logoMarkClass} />
    </NavLink>
  );
}

export default Logo;
