import it from "faker/lib/locales/it";
import React from "react";
import { NavLink } from "react-router-dom";

function FooterLinks({ title, footerItems }) {
  return (
    <div>
      <h3 className="text-xl font-bold text-white mb-4">{title}</h3>
      <div className="flex items-center">
        <ul className="leading-9 flex-1">
          {footerItems.map((item, idx) => (
            <li className="text-white" key={idx}>
              <a href={item?.path}>
                <span className="capitalize font-light">{item?.name}</span> 
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default FooterLinks;
