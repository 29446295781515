import React, { useEffect, useState, useContext } from "react";
import { useQuery, useMutation } from "urql";
import ReactPaginate from "react-paginate";
import {
  FollowMutation,
  UnfollowMutation,
  GetFollowersQuery,
} from "../../Queries";
import { useToasts } from "react-toast-notifications";
import { Link, useParams } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

const Follower = ({ userId, count }) => {
  const { addToast } = useToasts();
  const { auth } = useContext(AuthContext);
  const params = useParams();
  const slug = params?.slug;
  const [page, setPage] = useState(0);
  const [pageCount, setpageCount] = useState(count / 5);
  const [followersResult, executeFollowersQuery] = useQuery({
    query: GetFollowersQuery,
    pause: true,
    variables: {
      user_id: userId,
      id: auth?.userId,
      limit: 5,
      offset: page * 5,
    },
  });

  const {
    data: followersData,
    fetching: followersFetching,
    error: followersError,
  } = followersResult;

  useEffect(() => {
    executeFollowersQuery({ requestPolicy: "network-only" });
  }, [page]);
  const [{ fetching: followFetching }, executeFollowMutation] =
    useMutation(FollowMutation);
  const [{ fetching: unFollowFetching }, executeunFollowMutation] =
    useMutation(UnfollowMutation);
  const followHandler = (followerId) => {
    executeFollowMutation({
      user: userId,
      follower: followerId,
    });
    executeFollowersQuery({ requestPolicy: "network-only" });
    addToast("Successfully followed");
  };
  const unFollowHandler = (followerId) => {
    executeunFollowMutation({
      follower_id: followerId,
      user_id: userId,
    });
    executeFollowersQuery({ requestPolicy: "network-only" });
    addToast("Successfully unfollowed");
  };
  return (
    <div className="  rounded-md  min-w-64 p-3">
      <div className="h-64 min-w-64 ">
        {followersFetching ? (
          <div className="dark:text-gray-300 text-gray-500 ">loading...</div>
        ) : followersError ? (
          <div className="dark:text-gray-300 text-gray-500 ">
            error fetching followers
          </div>
        ) : followersData?.follow?.length < 1 ? (
          <div className="dark:text-gray-300 text-gray-500 ">
            {userId === auth?.userId
              ? "No one is following you yet."
              : "No one is following them yet. "}{" "}
          </div>
        ) : (
          followersData?.follow?.map((h, id) => (
            <Link
              key={id}
              to={`/user/${h?.user?.id}/view/collected`}
              className={"bg-purple-400"}
            >
              <div className="grid gap-5 grid-cols-2    ">
                <div className="flex my-2 space-x-2">
                  <img
                    src={h?.user?.image}
                    alt={h?.user?.username}
                    className="w-8 h-8 rounded-full object-cover"
                  />
                  <h3 className="cursor-not-allowed dark:text-gray-50 text-gray-800 text-sm  capitalize font-semibold mt-1">
                    {h?.user?.username}{" "}
                  </h3>
                </div>
                <div className="dark:text-gray-300 text-gray-500 text-sm mt-1">
                  {h?.user?.id === auth?.userId ? (
                    <div className=" mt-1 text-center font-semibold ">You </div>
                  ) : h?.user?.following_aggregate?.aggregate?.count < 1 ? (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        followHandler(h?.user?.id);
                      }}
                      className={`mx-2 w-full sm:text-xs xl:text-sm font-normal  bg-yellow-300 text-black dark:bg-grey-700 dark:text-white main-button flex border border-black dark:border-white items-center justify-center `}
                    >
                      Follow
                    </button>
                  ) : (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        unFollowHandler(h?.user?.id);
                      }}
                      className={`mx-2 w-full sm:text-xs xl:text-sm font-normal  bg-white text-black dark:bg-grey-700 dark:text-white main-button flex border border-black dark:border-white items-center justify-center `}
                    >
                      Unfollow
                    </button>
                  )}
                </div>
              </div>
            </Link>
          ))
        )}
      </div>
      {Math.ceil(pageCount) > 0 && followersData && (
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={(s) => {
            setPage(s?.selected);
          }}
          activeClassName="dark:text-gray-400 text-gray-600 font-semibold"
          disabledLinkClassName="cursor-not-allowed dark:text-gray-600 text-gray-400"
          disabledClassName="cursor-not-allowed dark:text-gray-600 text-gray-400"
          className="justify-center dark:text-gray-500 text-gray-500  capitalize font-semibold  space-x-4 flex items-center m-2"
          pageRangeDisplayed={5}
          pageCount={Math.ceil(pageCount)}
          previousLabel="< "
          renderOnZeroPageCount={null}
        />
      )}
    </div>
  );
};

export default Follower;
