import React from "react";

const PrimaryButton = ({
  text,
  Icon,
  iconClass,
  LeftIcon,
  className,
  disabled,
  ...rest
}) => {
  return (
    <button
      {...rest}
      disabled={disabled}
      className={`main-button sm:text-xs xl:text-sm font-normal bg-yellow-300 text-black dark:bg-grey-700 dark:text-white flex items-center justify-center border border-black dark:border-white ${
        disabled && "cursor-not-allowed opacity-25"
      } ${className}`}
    >
      {LeftIcon && <LeftIcon className={iconClass} />}
      {text}
      {Icon && <Icon className={iconClass} />}
    </button>
  );
};

export default PrimaryButton;
