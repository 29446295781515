import React from "react";
import FooterLinks from "./FooterLinks";
import Logo from "./Logo";
import {
  myAccountItems,
  resourceItems,
  companyItems,
} from "../utils/FooterMenus";
import { GetNFTCategoriesQuery } from "../Queries";
import { useQuery } from "urql";

function Footer() {
  const [categoriesResult] = useQuery({
    query: GetNFTCategoriesQuery,
  });
  const {
    data: categoriesData,
    fetching: categoriesFetching,
    error: categoriesError,
  } = categoriesResult;

  return (
    <footer className="bg-gray-900 py-5 xl:py-10 px-10 xl:px-16">
      <div className="sm:grid sm:grid-cols-10 sm:gap-5">
        <div className="col-span-2 mb-5 xl:mb-0">
          <Logo logoMarkClass="w-32" />
        </div>
        <div className="col-span-2 mb-5 xl:mb-0 ml-20">
          <FooterLinks title="My Account" footerItems={myAccountItems} />
        </div>
        <div className="col-span-2 mb-5 xl:mb-0 ml-20">
          <FooterLinks title="Resources" footerItems={resourceItems} />
        </div>
        <div className="col-span-2 mb-5 xl:mb-0 ml-20">
          <FooterLinks title="Company" footerItems={companyItems} />
        </div>
      </div>
    </footer>
  );
}

export default Footer;
