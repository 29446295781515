// nft_transaction, offers,nft, nft_bid,view, flags, view,categories

export const HeaderSearchQuery = `query HeaderSearchQuery($search: String) {
  user(where: {username: {_ilike: $search}}, limit: 5) {
    id
    email
    username
  }
  nft(where: {name: {_ilike: $search}}, limit: 5) {
    id
    name
  }
}
`;

export const NewDropsByCategoryNFTsQuery = `
query NewDropsByCategoryNFTQuery($where: nft_bool_exp = {is_listed: {_eq: true}}) {
  nft(order_by: {created_at: desc}, where: $where) {
    asset
    token_id
    description
    id
    name
    type
    reserved
    is_explicit
    owner_id
    price
    catogory {
      name
    }
    user {
      id
      image
      username
    }
    userByCreatorId {
      id
      image
      username
      wallet_id
    }
    is_listed
  }
}

`;

export const HotDropsByCategoryNFTsQuery = `
query HotDropsByCategoryNFTQuery($where: nft_bool_exp = { is_listed: {_eq: true}}) {
  nft(order_by: {created_at: desc, favourite_nfts_aggregate: {count: desc}}, where: $where) {
    asset
    token_id
    id
    name
    is_explicit
    owner_id
    reserved
    price
    userByCreatorId {
      id
      image
      username
      wallet_id
    }
    user {
      id
      username
      image
    }
    is_listed
    type
  }
}

`;

export const SearchForNFTsQuery = `
query SearchForNFTsQuery($limit: Int, $offset: Int, $name: String = "%%", $order_by: [nft_order_by!] = {}, $type: String = "%%", $is_listed: Boolean = true, $category: String = "%%", $user_id: [uuid!] = "", $price: String_comparison_exp = {}) {
  nft(where: {name: {_ilike: $name}, _and: {type: {_ilike: $type}, _and: {_and: {is_listed: {_eq: $is_listed}, price: $price}}}, catogory: {name: {_ilike: $category}}}, limit: $limit, offset: $offset, order_by: $order_by) {
    asset
    token_id
    description
    id
    name
    type
    is_explicit
    owner_id
    reserved
    price
    catogory {
      name
    }
    user {
      id
      image
      username
    }
    userByCreatorId {
      wallet_id
      id
      image
      username
      subscriptionsByUserId_aggregate(where: {subscriber_id: {_in: $user_id}}) {
        aggregate {
          count
        }
      }
    }
    is_listed
  }
}

`;
export const GetNFTCategoriesQuery = `
query GetCategoriesQuery {
    category {
      id
      name
      icon
      image
    }
  }
  
`;

export const RecordViewsMutation = `
mutation RecordViewMutation($nft: uuid = "", $user: uuid = "") {
  insert_view_one(object: {nft_id: $nft, user_id: $user}) {
    id
    user {
      username
    }
  }
}
`;

export const CreateFlagMutation = `
mutation ReportMutation($object: flags_insert_input = {}) {
  insert_flags_one(object: $object) {
    id
  }
}
`;

export const BidDataQuery = `
query BidDataQuery($id: uuid = "") {
  nft_bid(where: {open: {_eq: true}, nft: {id: {_eq: $id}}}) {
    bid_close_time
    bid_min_price
    bid_max_price
    id
  }
}
`;

export const NFTCheckQuery = `
query NFTCheckQuery($id: uuid = "", $user_id: uuid = "") {
  nft(where: {id: {_eq: $id}, owner_id: {_eq: $user_id}}) {
    id
  }
}
`;

export const CheckFavoritQuery = `
query ChckeFavoriteQuery($nft: uuid = "", $user: uuid = "") {
  favourite_nft(where: {nft_id: {_eq: $nft}, _and: {user_id: {_eq: $user}}}) {
    nft_id
    user_id
  }
}

`;

export const FavoriteMutation = `
mutation FavoriteMutation($nft: uuid = "", $user: uuid = "") {
  insert_favourite_nft_one(object: {nft_id: $nft, user_id: $user}) {
    user_id
    nft_id
  }
}
`;

export const UnfavoriteMutation = `
mutation UnfavoriteMutation($nft: uuid = "",$user: uuid = "") {
  delete_favourite_nft(where: {nft_id: {_eq: $nft}, _and: {}, user_id: {_eq: $user}}) {
    returning {
      nft_id
      user_id
    }
  }
}
`;

export const GetCreatedNFTQuery = `
query CreatedNFTDataQuery($owner_id: uuid_comparison_exp = {}, $user_id: [uuid!] = "") {
  nft(where: {creator_id: $owner_id, _and: { _and: {owner_id: $owner_id, _and: {hidden: {_eq: false}}}}}) {
    id
    price
    type
    token_id
    owner_id
    name
    description
    asset
    reserved
    user {
      image
      id
      username
    }
    userByCreatorId{
      wallet_id
      id
      image
      username
      subscriptionsByUserId_aggregate(where: {subscriber_id: {_in: $user_id}}) {
        aggregate {
          count
        }
      }
    }
    is_listed
  } 
}
`;

export const GetForSaleNFTQuery = `
query ListedNFTDataQuery($owner_id: uuid_comparison_exp = {}, $user_id: [uuid!] = "") {
  nft(where: {owner_id: $owner_id, is_listed: {_eq: true}, _and: {hidden: {_eq: false}}}) {
    id
    token_id
    name
    description
    asset
    owner_id
    reserved
    userByCreatorId{
      wallet_id
      id
      image
      username
      subscriptionsByUserId_aggregate(where: {subscriber_id: {_in: $user_id}}) {
        aggregate {
          count
        }
      }
    }
    user {
      image
      id
      username
    }
   
    views_aggregate {
      aggregate {
        count
      }
    }
    is_listed
    price
    type
  }
}
`;
export const GetColledtedNFTQuery = `query CollectedNFTDataQuery($user_id: uuid, $id: [uuid!] = "") {
  nft(where: {creator_id: {_neq: $user_id},  owner_id: {_eq: $user_id}}) {
    id
    name
    owner_id
    token_id
    description
    asset
    reserved
    user {
      image
      id
      username
      wallet_id
    }
    userByCreatorId {
      id
      image
      username
      wallet_id
      subscriptionsByUserId_aggregate(where: {subscriber_id: {_in: $id}}) {
        aggregate {
          count
        }
      }
    }
    is_listed
    type
    price
  }
}

`;

export const GetActivitiesQueries = `query GetActivitiesQuery($user_id: uuid = "", $limit: Int = 5, $offset: Int = 0) {
    nft_transaction_history(where: {_or: [{buyer: {_eq: $user_id}}, {seller: {_eq: $user_id}}]}, order_by: {created_at: desc}, limit: $limit, offset: $offset) {
      id
      event
      buyer
      seller
      price
      created_at
      nft {
        name
        id
      }
    }
    nft_transaction_history_aggregate(where: {_or: [{buyer: {_eq: $user_id}}, {seller: {_eq: $user_id}}]}){
      aggregate{
        count
      }
    }
  }
  `;
export const GetOffersQuery = `query GetOffersQuery($user_id: uuid = "", $limit: Int = 5, $offset: Int = 0) {
    offers(where: {nft: {creator_id: {_eq: $user_id}}}, order_by: {created_at: desc}, limit: $limit, offset: $offset) {
      id
      valid_till
      user {
        id
        username
      }
      price
      created_at
      nft {
        name
        id
      }
    }
    offers_aggregate(where: {nft: {creator_id: {_eq: $user_id}}}) {
      aggregate {
        count
      }
    }
  }
  `;
export const NFTQuery = `
query NFTQuery($id: uuid = "",$user_id:[uuid!]=[]) {
  nft_by_pk(id: $id) {
    id
    token_id
    asset
    category_id
    catogory{
      name
    }
    created_at
    description
    name
    is_explicit
    is_listed
    reserved
    user {
      username
      id
      image
    }
    userByCreatorId {
      wallet_id
      id
      username
      image
      bio
      links
      subscriptionsByUserId_aggregate(where: {subscriber_id: {_in: $user_id}}) {
        aggregate {
          count
        }
      }
    }
    views_aggregate {
      aggregate {
        count(columns: nft_id)
      }
    }
    collection {
      name
      id
    }
    owner_id
    type
    price
    bids_aggregate {
      aggregate {
        max {
          price
        }
      }
    }
  }
}
  `;

export const GetHistoryQuery = `
  query GetHistoryQuery($id: uuid = "", $_ilike: String = "%%", $offset: Int = 0,$limit: Int = 5) {
    nft_transaction_history(where: {nft_id: {_eq: $id}, event: {_ilike: $_ilike}}, order_by: {created_at: desc}, limit: $limit, offset: $offset) {
      created_at
      event
      id
      price
      buyerData {
        username
        id
        image
      }
      sellerData {
        id
        username
        image
      }
    }
    nft_transaction_history_aggregate(where: {nft_id: {_eq: $id}, event: {_ilike: $_ilike}})
    {
      aggregate
      {
        count
      }
    }
  }
  `;

export const GetMoreFromThisArtistQuery = `query GetHistoryQuery($id: uuid, $limit: Int = 10, $nft_id: uuid = "", $user_id: [uuid!] = "") {
  random_nft(limit: $limit, where: {creator_id: {_eq: $id}, id: {_neq: $nft_id}}) {
    id
    owner_id
    token_id
    name
    description
    asset
    reserved
    userByCreatorId {
      id
      image
      username
      subscriptionsByUserId_aggregate(where: {subscriber_id: {_in: $user_id}}) {
        aggregate {
          count
        }
      }
    }
    is_listed
    price
    type
  }
}

    
  
  `;
export const BidHistoryQuery = `query MyQuery($limit: Int = 5, $offset: Int = 10, $id: uuid = "") {
    nft_bid_history(limit: $limit, offset: $offset, order_by: {created_at: desc}, where: {nft_id: {_eq: $id}}) {
      id
      created_at
      user {
        image
        id
        username
      }
      price
    }
    nft_bid_aggregate(where: {nft_id: {_eq: $id}}) {
      aggregate {
        count
      }
    }
  }
  
  `;

export const TotalSecondarySalesQuery = `query TotalSalesQuery($id: uuid = "") {
    nft_transaction_history_aggregate(where: {_and: [{nft: {creator_id: {_eq: $id}}}, {seller: {_neq: $id}}]}) {
      aggregate {
        sum {
          price
        }
      }
    }
  }
  `;



export const GetFeedsByUserIdQuery = `query GetFeedsByUserIdQuery($user_id: uuid = "",$limit: Int, $offset: Int){
      nft_aggregate(limit: $limit, offset: $offset, where: {user: {followers: {follower_id: {_eq: $user_id}}}, _and: {is_listed: {_eq: true}}}) {
        nodes {
          asset
          category_id
          created_at
          creator_id
          description
          external_link
          id
          is_explicit
          is_listed
          name
          owner_id
          price
          token_uri
          token_id
          type
          updated_at
          reserved
          royalities
          hidden
          collection_id
          user {
            email
            id
            username
            wallet_id
            image
          }
          catogory {
            id
            name
          }
          userByCreatorId {
            id
            username
            wallet_id
            image
          }
        }
      }
      collection_aggregate(where: {user: {followers: {follower_id: {_eq: $user_id}}}}, limit: $limit, offset: $offset) {
        nodes {
          banner_image
          category_id
          contract_address
          created_at
          creator_id
          description
          display_theme
          id
          is_explicit
          logo_image
          name
          royalities
          total_sales
          updated_at
          url
          user {
            email
            id
            image
            username
            wallet_id
          }
          category {
            id
            name
          }
          nfts(limit: 2, offset: 0, where: {is_listed: {_eq: true}}) {
              asset
              category_id
              created_at
              creator_id
              description
              external_link
              id
              is_explicit
              is_listed
              name
              owner_id
              price
              token_uri
              token_id
              type
              updated_at
              reserved
              royalities
              hidden
              collection_id
            }
        }
      }

  }`



export const GetFeedsByUserIdCountQuery = `query GetFeedsByUserIdQuery($user_id: uuid = ""){
  nft_aggregate(where: {user: {followers: {follower_id: {_eq: $user_id}}}, _and: {is_listed: {_eq: true}}}) {
    aggregate{
      count
    }
  }
  collection_aggregate(where: {user: {followers: {follower_id: {_eq: $user_id}}}}) {
    aggregate{
      count
    }
  }  
}`
