import React, { useEffect, useState, useContext } from "react";
import { useQuery, useMutation } from "urql";
import ReactPaginate from "react-paginate";
import { NavLink } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import {
  FollowMutation,
  UnfollowMutation,
  GetFollowingQuery,
} from "../../Queries";
import { Link, useParams } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

const Following = ({ userId, count }) => {
  const { addToast } = useToasts();
  const { auth } = useContext(AuthContext);
  const params = useParams();
  const slug = params?.slug;
  const [page, setPage] = useState(0);
  const [pageCount, setpageCount] = useState(count / 5);
  const [followingResult, executeFollowingQuery] = useQuery({
    query: GetFollowingQuery,
    variables: {
      user_id: userId,
      id: auth?.userId,
      limit: 5,
      offset: page * 5,
    },
  });
  const {
    data: followingData,
    fetching: followingFetching,
    error: followingError,
  } = followingResult;
  useEffect(() => {
    executeFollowingQuery({ requestPolicy: "network-only" });
  }, [page]);
  const [{ fetching: unFollowFetching }, executeunFollowMutation] =
    useMutation(UnfollowMutation);
  const unFollowHandler = async (followerId) => {
    console.log("followerId", followerId);
    await executeunFollowMutation({
      follower_id: followerId,
      user_id: userId,
    });
    await executeFollowingQuery({ requestPolicy: "network-only" });
    addToast("Successfully unfollowed");
  };
  const [{ fetching: followFetching }, executeFollowMutation] =
    useMutation(FollowMutation);
  const followHandler = async (followerId) => {
    await executeFollowMutation({
      user: userId,
      follower: followerId,
    });
    await executeFollowingQuery({ requestPolicy: "network-only" });
    addToast("Successfully followed");
  };
  return (
    <div className=" w-full rounded-md  min-w-64 p-3">
      <div className="h-64 min-w-64 ">
        {followingFetching ? (
          <div className="dark:text-gray-300 text-gray-500 ">loading...</div>
        ) : followingError ? (
          <div className="dark:text-gray-300 text-gray-500 ">
            error fetching followings
          </div>
        ) : followingData?.follow?.length < 1 ? (
          <div className="dark:text-gray-300 text-gray-500 ">
            {userId === auth?.userId
              ? "You are not following anyone. Check out top creators and follow."
              : "They are not following anyone."}
          </div>
        ) : (
          followingData?.follow?.map((h, id) => (
            <NavLink key={id} to={`/user/${h?.follower?.id}/view/collected`}>
              <div className="grid gap-5 grid-cols-2  ">
                <div className="flex my-2 space-x-2">
                  <img
                    src={h?.follower?.image}
                    alt={h?.follower?.username}
                    className="w-8 h-8 rounded-full object-cover"
                  />
                  <h3 className="cursor-not-allowed dark:text-gray-50 text-gray-800 text-sm  capitalize font-semibold mt-1">
                    {h?.follower?.username}{" "}
                  </h3>
                </div>
                <div className="dark:text-gray-300 text-gray-500 w-full text-sm mt-1">
                  {h?.follower?.id === auth?.userId ? (
                    <div className=" mt-1 text-center font-semibold ">You </div>
                  ) : h?.user?.following_aggregate?.aggregate?.count < 1 ? (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        followHandler(h?.follower?.id);
                      }}
                      className={`mx-2 w-full sm:text-xs xl:text-sm font-normal  bg-yellow-300 text-black dark:bg-grey-700 dark:text-white main-button flex border border-black dark:border-white items-center justify-center `}
                    >
                      Follow
                    </button>
                  ) : (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        unFollowHandler(h?.follower?.id);
                      }}
                      className={`mx-2 w-full sm:text-xs xl:text-sm font-normal  bg-white text-black dark:bg-grey-700 dark:text-white main-button flex border border-black dark:border-white items-center justify-center `}
                    >
                      Unfollow
                    </button>
                  )}
                </div>
              </div>
            </NavLink>
          ))
        )}
      </div>
      {Math.ceil(pageCount) > 0 && followingData && (
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={(s) => {
            setPage(s?.selected);
          }}
          activeClassName="dark:text-gray-400 text-gray-600 font-semibold"
          disabledLinkClassName="cursor-not-allowed dark:text-gray-600 text-gray-400"
          disabledClassName="cursor-not-allowed dark:text-gray-600 text-gray-400"
          className="justify-center dark:text-gray-500 text-gray-500  capitalize font-semibold  space-x-4 flex items-center m-2"
          pageRangeDisplayed={5}
          pageCount={Math.ceil(pageCount)}
          previousLabel="< "
          renderOnZeroPageCount={null}
        />
      )}
    </div>
  );
};

export default Following;
