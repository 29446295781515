// user, followers, followings, subscribers , favourite

export const CheckUsernameQuery = `
query CheckUsernameQuery($username: String, $user_id: uuid) {
  user(where: { _and: [
    {username: {_eq: $username}},
    {id: {_neq: $user_id}}
  ] }) {
    id
    username
  }
}
`;
export const GetUserDataQuery = `
query GetUserDataQuery($user_id: uuid = "", $me: [uuid!] = []) {
  user_by_pk(id: $user_id) {
    bio
    cover_image
    email
    id
    image
    links
    username
    wallet_id
    notification
    verified
    show_wallet_id
    me_following: following_aggregate(where: {user_id: {_in: $me}}) {
      aggregate {
        count
      }
    }
    me_subscribed: subscriptionsByUserId_aggregate(where: {subscriber_id: {_in: $me}}) {
      aggregate {
        count
      }
    }
    followers_aggregate {
      aggregate {
        count
      }
    }
    following_aggregate {
      aggregate {
        count
      }
    }
    subscriptionsByUserId_aggregate {
      aggregate {
        count
      }
    }
  }
}


`;

export const UpdateMeMutation = `
mutation UpdateMeMutation($user_id: uuid = "", $set_data: user_set_input = {}) {
  update_user_by_pk(pk_columns: {id: $user_id}, _set: $set_data) {
    id
    username
  }
}
`;
export const GetUserFavoriteNFTQuery = `
query UserFavoriteNFTData($user_id: uuid = "") {
  favourite_nft(where: {user_id: {_eq: $user_id}}) {
    nft {
      token_id
      asset
      id
      name
      description
      user {
        image
        id
        username
      }
      userByCreatorId{
        id
        image
        username
        wallet_id
      }
      favourite_nfts_aggregate {
        aggregate {
          count
        }
      }
      is_listed
      type
      price
    }
  }
}
`;

export const GetSubscribersQuery = `query GetSubscribersQuery($user_id: uuid = "", $id: uuid = "",$limit: Int , $offset: Int =0) {
  subscriptions(where: {creator_id: {_eq: $user_id}}, limit: $limit, offset: $offset) {
    subscriber {
      image
      id
      wallet_id
      username
      subscriptionsByUserId_aggregate(where: {subscriber_id: {_eq: $id}}){
        aggregate{
          count
        }
      }
    }
  }
}`;
export const GetFollowersQuery = `
query GetFollowersQuery($user_id: uuid = "",$id: uuid = "", $limit: Int , $offset: Int =0) {
  follow(where: {follower_id: {_eq: $user_id}}, limit: $limit, offset: $offset) {
    id
    user {
      image
      id
      wallet_id
      username
      following_aggregate(where: {user_id: {_eq: $id}}){
        aggregate{
          count
        }
      }
    }
  }
}

`;

export const GetFollowingQuery = `
query GetFollowingQuery($user_id: uuid = "",$id: uuid = "", $limit: Int , $offset: Int = 0) {
  follow(where: {user_id: {_eq: $user_id}}, limit: $limit, offset: $offset) {
    id
    follower {
      image
      id
      wallet_id
      username
      following_aggregate(where:{user_id:{_eq:$id}}){
        aggregate{
          count
        }
      }
    }
  }
}

`;

export const CheckIfFollowingQuery = `
query CheckIfFollowingQuery($user: uuid = "", $follower: uuid = "") {
  follow(where: {user_id: {_eq: $user}, _and: {follower_id: {_eq: $follower}}}) {
    id
  }
}
`;

export const FollowMutation = `
mutation FollowMutation($follower: uuid = "", $user: uuid = "") {
  insert_follow_one(object: {follower_id: $follower, user_id: $user}) {
    id
    follower_id
    user_id
  }
}
`;

export const UnfollowMutation = `
mutation UnFollowMutation($follower_id: uuid = "", $user_id: uuid = "") {
  delete_follow(where: {follower_id: {_eq: $follower_id}, user_id: {_eq: $user_id}}) {
    returning {
      id
    }
  }
}

`;
export const UnfollowByPK = `mutation UnfollowByPK($id: uuid = "") {
  delete_follow_by_pk(id: $id){
    id
  }
}
`;

export const CheckIfSubscribedQuery = `
query CheckSubscriptionQuery($creator: uuid = "", $subscriber: uuid = "") {
  subscriptions(where: {creator: {id: {_eq: $creator}}, subscriber: {id: {_eq: $subscriber}}}) {
    subscriber {
      id
    }
    creator {
      id
    }
  }
}
`;
export const UpdateNotificationsMutation = `
mutation UpdateNotificationsMutation($id: uuid = "", $notification: jsonb = "") {
  update_user_by_pk(pk_columns: {id: $id}, _set: {notification: $notification}) {
    id
  }
}
`;
export const UserQuery = `query UserQuery($limit: Int , $offset: Int = 0, $sort: [user_order_by!] = {created_at: asc}, $created_at: timestamptz_comparison_exp = {}) {
  user(limit: $limit, offset: $offset, where: {created_at: $created_at}, order_by: $sort) {
    image
    username
    id
    created_nfts_aggregate(where: {is_listed: {_eq: true}}) {
      aggregate {
        count
      }
    }
    subscriptionsByUserId_aggregate {
      aggregate {
        count
      }
    }
    nft_transaction_histories_sell_aggregate {
      aggregate {
        sum {
          priceint
        }
      }
    }
  }
  user_aggregate(where: {created_at: $created_at}) {
    aggregate {
      count
    }
  }
}
  `;
export const GetSubscriptionsQuery = `
  query GetSubscriptionsQuery($subscriber: uuid) {
    subscriptions(where: {subscriber: {id: {_eq: $subscriber}}}) {
      creator {
        id
        image
        username
        wallet_id
      }
    }
  }
  `;
export const SubscribeMutation = `
mutation SubscribeMutation($creator_id: uuid = "", $subscriber_id: uuid = "") {
  insert_subscriptions_one(object: {creator_id: $creator_id, subscriber_id: $subscriber_id}, on_conflict: {constraint: subscriptions_creator_id_subscriber_id_key, update_columns: ends_at}) {
    id
    subscriber {
      id
    }
    creator {
      id
    }
  }
}

 
`;

export const GetTopCreatorsQuery = `
query GetTopCreatorsQuery($where: user_bool_exp = {created_nfts: {category_id: {_eq: ""}}}) {
  user(order_by: {created_nfts_aggregate: {count: desc}}, where: $where, limit: 12) {
    id
    image
    wallet_id
    username
  }
}

  
  `;

export const GetNotifications = `query GetNotifications($offset: Int = 0, $limit: Int = 10) {
  notifications(offset: $offset, limit: $limit, order_by: {is_new: desc, created_at: desc}) {
    created_at
    date
    description
    is_new
    id
    nft_id
    subject
    text
    updated_at
    nft {
      asset
    }
  }
  countNew: notifications_aggregate(where: {is_new: {_eq: true}}) {
    aggregate {
      count
    }
  }
  notifications_aggregate {
    aggregate {
      count
    }
  }
}
`;
export const UpdateNotificationState = `
mutation UpdateNotification($id: uuid = "") {
  update_notifications_by_pk(pk_columns: {id: $id}, _set: {is_new: false}) {
    id
  }
}
`;
export const DeleteNotifications = `mutation DeleteNotifications {
  delete_notifications(where: {is_new: {_eq: false}}) {
    affected_rows
  }
}`;
