import React, { useEffect, useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import { Button } from "@windmill/react-ui";
import { useModalState, useModalAction } from "../../context/modalContext";
import { useToasts } from "react-toast-notifications";
import { baseAPIUrl } from "../../config";
import { bidNft } from "../../contracts";
import useFetch, { CachePolicies } from "use-http";

const currencies = [
  {
    name: "ETH",
    value: "eth",
  },
];

const PlaceBidPopup = () => {
  const [terms, setTerms] = useState(false);
  const [amount, setAmount] = useState();
  const [currency, setCurrency] = useState(currencies[0].value);
  const { data } = useModalState();
  const { openModal, closeModal } = useModalAction();
  const { currentPrice, bidData, auth, nftItem } = data;
  const { nft_bid } = bidData;
  const [disabled, setDisabled] = useState(true);
  const [waiting, setWaiting] = useState(false);

  const { addToast } = useToasts();
  const { post, response, loading, error } = useFetch(baseAPIUrl, {
    headers: { Authorization: `Bearer ${auth?.token}` },
    cachePolicy: "no-cache",
  });

  useEffect(() => {
    if (amount && parseFloat(amount) > parseFloat(currentPrice)) {
      setDisabled(false);
    } else setDisabled(true);
  }, [amount]);

  const placeBid = async () => {
    setWaiting(true);
    try {
      if (
        typeof window.ethereum !== "undefined" &&
        window.ethereum.networkVersion === process.env.REACT_APP_CHAINID
      ) {
        const contractInteraction = await bidNft(
          window?.ethereum,
          parseInt(nftItem?.token_id),
          amount
        );
        if (contractInteraction?.tokenId) {
          const updateNft = await post("nft/place_bid", {
            price: amount,
            id: data?.nftItem?.id,
          });
          if (updateNft?.message === "success") {
            addToast("NFT Auctioned Successfully .", { appearance: "success" });
            closeModal("PLACE_BID_VIEW");
            openModal("SUCCESS_VIEW", { text: "Bid Placed Successfully" });
            // history("/profile/view/collected");
          } else {
            throw "NFT couldn't be auctioned";
          }
        } else {
          throw contractInteraction?.error;
        }
      } else {
        throw "Wrong network! Please connect to the correct network.";
      }
    } catch (error) {
      // console.log(error);
      if (error.toString().includes("unknown account #0")) {
        addToast("Please connect to wallet first!", { appearance: "error" });
      } else {
        addToast(error?.reason || error, {
          appearance: "error",
          autoDismiss: true,
        });
      }
    }
    setWaiting(false);
  };

  return (
    <div>
      <h3 className="text-lg font-semibold mb-4 dark:text-white">Place Bid</h3>

      <div className="mb-6">
        <label htmlFor="price" className="text-lg font-medium mb-2">
          Price
        </label>
        <div className="flex">
          <select
            name="currency"
            value={currency}
            className="border-2 border-gray-600 rounded-tl-lg rounded-bl-lg px-6 py-4 w-32 focus:outline-none font-medium"
            onChange={(e) => console.log(e.target.value)}
          >
            {currencies.map((item, idx) => (
              <option key={idx} value={item.value}>
                {item.name}
              </option>
            ))}
          </select>
          <input
            type="text"
            placeholder="Amount"
            className="border-2 border-gray-600 border-l-0 border-r-0 px-6 py-4 flex-1 focus:outline-none"
            onChange={(e) => setAmount(e.target.value)}
          />
          <input
            type="text"
            placeholder="USD"
            className="border-2 border-gray-600 px-6 py-4 rounded-tr-lg rounded-br-lg w-32 focus:outline-none"
          />
        </div>
      </div>

      <div className="flex items-center mt-4">
        <input
          type="checkbox"
          className="cursor-pointer"
          checked={terms}
          onChange={() => setTerms((prevState) => !prevState)}
        />
        <span className="text-xs text-gray-600 ml-2">
          By checking this box, I agree to Gruve’s Terms of Service
        </span>
      </div>
      <div className="text-right mt-6">
        <Button
          disabled={!terms || waiting || disabled}
          className="bg-purple-600 ml-auto py-3 px-12 rounded-lg shadow-xl text-white flex items-center"
          onClick={() => {
            placeBid();
          }}
        >
          <span>Place Bid</span>
          <FaArrowRight className="ml-6" />
        </Button>
      </div>
    </div>
  );
};

export default PlaceBidPopup;
