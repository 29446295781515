const routes = [
  {
    path: "/explore", // the url
    icon: "SearchIcon", // the component being exported from icons/index.js
    name: "Explore", // name that appear in Sidebar
  },
  {
    path: "/news", // the url
    icon: "MailIcon", // the component being exported from icons/index.js
    name: "News", // name that appear in Sidebar
  },
  {
    path: "/subscriptions", // the url
    icon: "MailIcon", // the component being exported from icons/index.js
    name: "Subscriptions", // name that appear in Sidebar
  },
  {
    path: "/create/create-new-nft", // the url
    icon: "FormsIcon", // the component being exported from icons/index.js
    name: "Create", // name that appear in Sidebar
  },
  {
    icon: "PeopleIcon",
    name: "My Account",
    routes: [
      // submenu
      {
        path: "/login",
        name: "Login or Create Account",
      },
    ],
  },
];

export default routes;
