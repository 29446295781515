import React, { Component, useEffect, useState } from "react";

import { useMutation } from "urql";
import { UpdateNotificationState } from "../../Queries";

export default function NotificationCard({ notification, executeUpdate }) {
  useEffect(() => {
    if (notification?.is_new) {
      executeUpdate({ id: notification?.id });
    }
  }, []);
  return (
    <div className="grid my-3 items-center grid-cols-2 text-sm  w-full">
      <div className="flex gap-1 w-64  items-center">
        <img
          src={notification?.nft?.asset}
          className="h-10 w-10 object-cover"
          alt="nft asset"
        />
        <div>{notification?.description}</div>
      </div>
      <div className="flex justify-end  ">
        {new Date(notification?.date).toLocaleDateString()}
      </div>
    </div>
  );
}
