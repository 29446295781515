import React, { useState, useContext, useEffect } from "react";
import { FaArrowRight } from "react-icons/fa";
import { Button } from "@windmill/react-ui";
import { useModalAction, useModalState } from "../../context/modalContext";
import { AuthContext } from "../../context/AuthContext";
import { useToasts } from "react-toast-notifications";
import { baseAPIUrl } from "../../config";
import Countdown, { CountdownApi } from "react-countdown";
import { AiFillSetting } from "react-icons/ai";

const DescriptionPopup = ({ description, createdBy, isCreator, collectionData, openModal }) => {
  return (
    <div className="description-popup-class">
      <p>{description}</p>
      <div className="created-by-section">
        Created by: <span className="font-bold">{createdBy}</span>
        {isCreator && (
          <AiFillSetting
            title="Click to update"
            onClick={(e) => {
              e.preventDefault();
              openModal("EDIT_COLLECTION_POPUP", collectionData);
            }}
            className="edit-icon-class"
          />
        )}
      </div>
    </div>
  );
}

export default DescriptionPopup;
