import { ethers } from "ethers";
import nftMarketplace_Abi from "./nftMarketplace_Abi.json";
import subscription_Abi from "./subscription_Abi.json";
const marketPlaceAddress = process.env.REACT_APP_MARKETPLACE_ADDRESS;
const subscriptionAddress = process.env.REACT_APP_SUBSCRIPTION_ADDRESS;

export const createNFT = async (ethereum, token_uri, royalities, reserved) => {
  try {
    if (
      typeof ethereum !== "undefined" &&
      ethereum.networkVersion === process.env.REACT_APP_CHAINID
    ) {
      const provider = new ethers.providers.Web3Provider(ethereum);
      const signer = provider.getSigner();
      const addr = await signer.getAddress();
      const contract = new ethers.Contract(
        marketPlaceAddress,
        nftMarketplace_Abi,
        signer
      );
      // need to pass tokenURI , royalty and reserved
      const tx = await contract.createNFT(
        token_uri, //pinata image
        royalities,
        reserved
      );
      const receipt = await tx.wait();
      const tokenId = receipt.events[0].args.tokenId.toString();
      console.log("Token ID: ", tokenId);
      return { tokenId };
    } else {
      throw "Wrong network! Please connect to the correct network.";
    }
  } catch (e) {
    return { error: e };
  }
};
export const listNFT = async (ethereum, token_id, price) => {
  try {
    if (
      typeof ethereum !== "undefined" &&
      ethereum.networkVersion === process.env.REACT_APP_CHAINID
    ) {
      const provider = new ethers.providers.Web3Provider(ethereum);
      const signer = provider.getSigner();
      const addr = await signer.getAddress();
      const contract = new ethers.Contract(
        marketPlaceAddress,
        nftMarketplace_Abi,
        signer
      );
      //get the listing fee
      const listingFee = await contract.listingFee();
      // need to pass tokenId , price, and listing charge
      const tx = await contract.listNFT(
        parseInt(token_id),
        ethers.utils.parseEther(price),
        { value: listingFee.toString() }
      );
      const receipt = await tx.wait();
      const tokenId = receipt.events[0].args.tokenId.toString();
      console.log("Token ID: ", tokenId);
      return { tokenId };
    } else {
      throw "Wrong network! Please connect to the correct network.";
    }
  } catch (e) {
    return { error: e };
  }
};
export const listToBid = async (ethereum, token_id, minbid, days) => {
  try {
    if (
      typeof ethereum !== "undefined" &&
      ethereum.networkVersion === process.env.REACT_APP_CHAINID
    ) {
      const provider = new ethers.providers.Web3Provider(ethereum);
      const signer = provider.getSigner();
      const addr = await signer.getAddress();
      const contract = new ethers.Contract(
        marketPlaceAddress,
        nftMarketplace_Abi,
        signer
      );
      //get the listing fee
      const listingFee = await contract.listingFee();
      // need to pass tokenId , starting price and duration
      const tx = await contract.startAuction(
        parseInt(token_id),
        ethers.utils.parseEther(minbid),
        days,
        { value: listingFee.toString() }
      );
      const receipt = await tx.wait();
      const tokenId = receipt.events[0].args.tokenId.toString();
      console.log("Token ID: ", tokenId);
      return { tokenId };
    } else {
      throw "Wrong network! Please connect to the correct network.";
    }
  } catch (e) {
    return { error: e };
  }
};
export const buyNft = async (ethereum, token_id, price) => {
  try {
    if (
      typeof ethereum !== "undefined" &&
      ethereum.networkVersion == process.env.REACT_APP_CHAINID
    ) {
      const provider = new ethers.providers.Web3Provider(ethereum);
      const signer = provider.getSigner();
      const addr = await signer.getAddress();
      const contract = new ethers.Contract(
        marketPlaceAddress,
        nftMarketplace_Abi,
        signer
      );
      // need to pass tokenId , price
      const tx = await contract.buyNFT(parseInt(token_id), {
        value: ethers.utils.parseEther(price),
      });
      const receipt = await tx.wait();
      const tokenId = receipt.events[0].args.tokenId.toString();
      console.log("Token ID: ", tokenId);
      return { tokenId };
    } else {
      throw "Wrong network! Please connect to the correct network.";
    }
  } catch (e) {
    console.log(e);
    return { error: e };
  }
};
export const bidNft = async (ethereum, token_id, price) => {
  try {
    if (
      typeof ethereum !== "undefined" &&
      ethereum.networkVersion === process.env.REACT_APP_CHAINID
    ) {
      const provider = new ethers.providers.Web3Provider(ethereum);
      const signer = provider.getSigner();
      const addr = await signer.getAddress();
      const contract = new ethers.Contract(
        marketPlaceAddress,
        nftMarketplace_Abi,
        signer
      );
      // need to pass tokenId and amount
      const tx = await contract.bid(parseInt(token_id), {
        value: ethers.utils.parseEther(price),
      });
      const receipt = await tx.wait();
      const tokenId = receipt.events[0].args.tokenId.toString();
      console.log("Token ID: ", tokenId);
      return { tokenId };
    } else {
      throw "Wrong network! Please connect to the correct network.";
    }
  } catch (e) {
    console.log(e);
    return { error: e };
  }
};
export const getHighestBid = async (ethereum, token_id) => {
  try {
    if (
      typeof ethereum !== "undefined" &&
      ethereum.networkVersion === process.env.REACT_APP_CHAINID
    ) {
      const provider = new ethers.providers.Web3Provider(ethereum);
      const signer = provider.getSigner();
      const addr = await signer.getAddress();
      const contract = new ethers.Contract(
        marketPlaceAddress,
        nftMarketplace_Abi,
        signer
      );
      // need to pass tokenId
      const nftDetails = await contract.getNFTDetails(token_id);

      const isAuction = nftDetails[8];
      const isReserved = nftDetails[7];
      let highestBid = 0;
      if (isAuction) {
        highestBid = await contract.currentHighestBid(token_id); //need to pass tokenId
        // console.log("highest bid: ", highestBid);
      }
      return { isReserved, highestBid: ethers.utils.formatEther(highestBid) };
    } else {
      throw "Wrong network! Please connect to the correct network.";
    }
  } catch (e) {
    console.log(e);
    return { error: e };
  }
};
export const endBidNft = async (ethereum, token_id) => {
  try {
    if (
      typeof ethereum !== "undefined" &&
      ethereum.networkVersion === process.env.REACT_APP_CHAINID
    ) {
      const provider = new ethers.providers.Web3Provider(ethereum);
      const signer = provider.getSigner();
      const addr = await signer.getAddress();
      const contract = new ethers.Contract(
        marketPlaceAddress,
        nftMarketplace_Abi,
        signer
      );
      const getPrice = await getHighestBid(ethereum, token_id);
      // need to pass tokenId
      const tx = await contract.end(parseInt(token_id));
      const receipt = await tx.wait();
      console.log(receipt, "reciept");
      const tokenId = receipt.events[0].args.tokenId.toString();
      const buyer = await contract.ownerOf(token_id);
      console.log("Token ID: ", tokenId, "buyer", buyer);
      return { tokenId, buyer, highestBid: getPrice?.highestBid };
    } else {
      throw "Wrong network! Please connect to the correct network.";
    }
  } catch (e) {
    console.log(e);
    return { error: e };
  }
};

export const checkSubscribed = async (ethereum, artist, user) => {
  try {
    if (
      typeof ethereum !== "undefined" &&
      ethereum.networkVersion === process.env.REACT_APP_CHAINID
    ) {
      const provider = new ethers.providers.Web3Provider(ethereum);
      const signer = provider.getSigner();
      const addr = await signer.getAddress();
      const contract = new ethers.Contract(
        subscriptionAddress,
        subscription_Abi,
        signer
      );
      // need to pass tokenId
      const isSubscribed = await contract.isSubscribed(user, artist);

      return { isSubscribed };
    } else {
      throw "Wrong network! Please connect to the correct network.";
    }
  } catch (e) {
    console.log(e);
    return { error: e };
  }
};
