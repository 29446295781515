import React from "react";
import CheckoutPopup from "../Checkout/CheckoutPopup";
// import MakeOfferPopup from "../Checkout/MakeOfferPopup";
import PlaceBidPopup from "../Checkout/PlaceBidPopup";
import SuccessPopup from "../Checkout/SuccessPopup";
import Modal from "./modal";
import { useModalAction, useModalState } from "../../context/modalContext";
import ReportPopup from "./ReportPopup";
import SellPopup from "../Sell/SellPopup";
import SellPopupWallet from "../Sell/SellPopupWallet";
import PlaceBidSellPopup from "../Sell/PlaceBidSellPopup";
import SubscribePopup from "../Subscribe/SubscribePopup";
import UserProfilePopup from "../Profile/UserProfilePopup";
import OfferPopup from "../Profile/OfferPopup";
import DescriptionPopUp from "../Collection/DescriptionPopup";
import RenewSubscriptionPopup from "../Subscribe/RenewSubscriptionPopup";
const ManagedModal = () => {
  const { isOpen, view } = useModalState();
  const { closeModal } = useModalAction();

  return (
    <Modal open={isOpen} onClose={closeModal}>
      {view === "CHECKOUT_VIEW" && <CheckoutPopup />}
      {/* {view === "MAKE_OFFER_VIEW" && <MakeOfferPopup />} */}
      {view === "SUCCESS_VIEW" && <SuccessPopup />}
      {view === "PLACE_BID_VIEW" && <PlaceBidPopup />}
      {view === "REPORT_POPUP" && <ReportPopup />}
      {view === "SELL_POPUP" && <SellPopup />}
      {view === "SELL_POPUP_WALLET" && <SellPopupWallet />}
      {view === "PLACE_BID_SELL_POPUP" && <PlaceBidSellPopup />}
      {view === "SUBSCRIBE_USER_POPUP" && <SubscribePopup />}
      {view === "RENEW_SUBSCRIPTION" && <RenewSubscriptionPopup />}
      {view === "USER_PROFILE_POPUP" && <UserProfilePopup />}
      {view === "OFFER_POPUP" && <OfferPopup />}
      {view === "DESCRIPTION_POPUP" && <DescriptionPopUp />}
    </Modal>
  );
};

export default ManagedModal;
