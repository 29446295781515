import React, { useContext } from "react";
import { Route, Navigate, Routes } from "react-router-dom";
import { AuthContext } from "./context/AuthContext";
import Layout from "./containers/Layout";
const PrivateRoute = ({ children, ...rest }) => {
  const { auth } = useContext(AuthContext);
  const { loading } = auth;

  return (
    <>
      {loading ? (
        <Routes>
          <Route
            {...rest}
            render={() => {
              return <p>Loading...</p>;
            }}
          />
        </Routes>
      ) : auth?.token && auth?.token?.split() !== "" ? (
        <Layout children={children} />
      ) : (
        <Navigate to="/login" replace />
      )}
    </>
  );
};

export default PrivateRoute;
