import React, { useState, useContext, useEffect } from "react";
import { FaArrowRight } from "react-icons/fa";
import { Button } from "@windmill/react-ui";
import { useModalAction, useModalState } from "../../context/modalContext";
import { AuthContext } from "../../context/AuthContext";
import { useToasts } from "react-toast-notifications";
import { baseAPIUrl } from "../../config";
import { buyNft } from "../../contracts";
import useFetch, { CachePolicies } from "use-http";
import { useNavigate } from "react-router-dom";

const CheckoutPopup = () => {
  const [terms, setTerms] = useState(false);
  const { data } = useModalState();
  const { openModal, closeModal } = useModalAction();
  const { auth } = useContext(AuthContext);
  const { post, response, loading, error } = useFetch(baseAPIUrl, {
    headers: { Authorization: `Bearer ${auth?.token}` },
    cachePolicy: "no-cache",
  });
  const history = useNavigate();

  const { addToast } = useToasts();
  const [waiting, setWaiting] = useState(false);

  const buyNFT = async () => {
    setWaiting(true);

    try {
      if (
        typeof window.ethereum !== "undefined" &&
        window.ethereum.networkVersion === process.env.REACT_APP_CHAINID
      ) {
        const contractInteraction = await buyNft(
          window?.ethereum,
          parseInt(data?.nftItem?.token_id),
          data?.nftItem?.price.toString()
        );
        if (contractInteraction?.tokenId) {
          const updateNft = await post("nft/checkout", {
            price: data?.nftItem?.price.toString(),
            id: data?.nftItem?.id,
          });
          if (updateNft?.message === "success") {
            addToast("NFT Bought Successfully .", { appearance: "success" });
            closeModal("CHECKOUT_VIEW");
            openModal("SUCCESS_VIEW");
            history("/profile/view/collected");
          } else {
            throw "NFT couldn't be listed";
          }
        } else {
          throw contractInteraction?.error;
        }
      } else {
        throw "Wrong network! Please connect to the correct network.";
      }
    } catch (error) {
      // console.log(error);
      if (error.toString().includes("unknown account #0")) {
        addToast("Please connect to wallet first!", { appearance: "error" });
      } else {
        addToast(error?.reason || error, { appearance: "error" });
      }
    }
    setWaiting(true);
  };
  console.log(data, "data");

  return (
    <div>
      <h3 className="text-lg font-semibold dark:text-white">
        Complete Checkout
      </h3>

      <div className="grid grid-cols-6 gap-4 mb-6 mt-4">
        <div className="col-span-4">
          <h5 className="text-lg mb-4 font-medium">Item</h5>
          <div className="flex items-center">
            <img
              src={data?.nftItem?.asset}
              alt="Item image"
              className="w-16 h-16"
            />
            <div className="nftInfo ml-4">
              <h3 className="text-xl font-medium">{data?.nftItem?.name}</h3>

              <h6 className="mb-2">{data?.nftItem?.collection?.name}</h6>
            </div>
          </div>
        </div>
        <div className="col-span-2 text-right">
          <h5 className="text-lg mb-4 font-medium">Subtotal</h5>
          <h4 className="text-lg font-medium mb-2">
            {data?.nftItem?.price} ETH
          </h4>
          <p className="text-sm">($ {data?.nftItem?.dollarRate})</p>
        </div>
      </div>
      <div className="p-6 bg-purple-100 dark:bg-cool-gray-900 rounded-lg">
        <div className="flex">
          <h5 className="text-lg font-medium">Total</h5>
          <div className="ml-auto text-right">
            <h5 className="text-lg font-medium mb-2">
              {data?.nftItem?.price} ETH
            </h5>
            <p>($ {data?.nftItem?.dollarRate})</p>
          </div>
        </div>
      </div>
      <div className="flex items-center mt-4">
        <input
          type="checkbox"
          className="cursor-pointer"
          checked={terms}
          onChange={() => setTerms((prevState) => !prevState)}
        />
        <span className="text-xs text-gray-600 ml-2">
          By checking this box, I agree to Gruve’s Terms of Service
        </span>
      </div>
      <div className="text-right mt-6">
        <Button
          disabled={!terms}
          onClick={() => {
            buyNFT();
          }}
          className="bg-purple-600 ml-auto py-3 px-12 rounded-lg shadow-xl text-white flex items-center"
        >
          {" "}
          {waiting ? (
            <span>Loading..</span>
          ) : (
            <>
              <span>Checkout</span>
              <FaArrowRight className="ml-6" />
            </>
          )}
        </Button>
      </div>
    </div>
  );
};

export default CheckoutPopup;
