import React, { useState, useContext, useEffect } from "react";
import { useModalAction, useModalState } from "../../context/modalContext";
import { AuthContext } from "../../context/AuthContext";
import { useToasts } from "react-toast-notifications";
import Follower from "./follower";
import Following from "./following";
import Subscribers from "./subscribers";
const modalTabs = ["followers", "subscribers", "followings"];

const UserProfilePopup = () => {
  const { auth } = useContext(AuthContext);
  const { data } = useModalState();
  const { openModal, closeModal } = useModalAction();
  const { addToast } = useToasts();
  return (
    <div className="w-full px-2 ">
      <div className="grid grid-cols-3 text-center items-center py-3 px-4 mb-4  dark:text-gray-300   border-b dark:border-gray-500">
        {modalTabs?.map((tab, i) => (
          <div
            key={i}
            onClick={(e) => {
              e.preventDefault();
              openModal("USER_PROFILE_POPUP", { ...data, tab: tab });
            }}
            className={`rounded-full capitalize p-2 gap-2 cursor-pointer text-gray-500 dark:text-gray-300 font-semibold ${
              data?.tab === tab &&
              "bg-gray-200 text-gray-600 dark:bg-cool-gray-600 dark:text-cool-gray-50 "
            }`}
          >
            {tab}
            <span className="space-x-2"> {data[tab]}</span>
          </div>
        ))}
      </div>
      <div className="grid w-full">
        {data?.tab === "followings" && (
          <Following userId={data?.userId} count={data?.followings} />
        )}
        {data?.tab === "followers" && (
          <Follower userId={data?.userId} count={data?.followers} />
        )}
        {data?.tab === "subscribers" && (
          <Subscribers
            userId={data?.userId}
            count={data?.subscribers}
            userData={data?.userData}
          />
        )}
      </div>
    </div>
  );
};

export default UserProfilePopup;
