import React, { useContext, Suspense, useEffect, lazy } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { privateRoutes, routes } from "../routes";
import { Helmet } from "react-helmet";

import Header from "../components/Header";
import Main from "../containers/Main";
import ThemedSuspense from "../components/ThemedSuspense";
import { SidebarContext } from "../context/SidebarContext";
import Footer from "../components/Footer";

const Page404 = lazy(() => import("../pages/404"));

function Layout({ children: Children }) {
  const { isSidebarOpen, closeSidebar } = useContext(SidebarContext);
  let location = useLocation();

  useEffect(() => {
    closeSidebar();
  }, [location]);
  // console.log("location", location);
  return (
    <div
      className={`bg-gray-50 dark:bg-gray-800 ${
        isSidebarOpen && "overflow-hidden"
      }`}
    >
      <Helmet>
        <title>Gruve | Home</title>
      </Helmet>
      <div className="flex flex-col flex-1 w-full">
        <Header />
        <Main>
          <Suspense fallback={<ThemedSuspense />}>
            <Children />
            {/* <Routes>
              {routes.map((route, i) => {
                return route.component ? (
                  <Route
                    errorElement={<div>error</div>}
                    key={i}
                    path={`${route.path}/*`}
                    element={<route.component />}
                  />
                ) : null;
              })}

              {privateRoutes.map((route, i) => {
                return route.component ? (
                  <Route
                    key={i}
                    path={`${route.path}/*`}
                    element={<route.component />}
                  />
                ) : null;
              })}

              <Route component={Page404} />
            </Routes> */}
          </Suspense>
        </Main>
        <Footer />
      </div>
    </div>
  );
}

export default Layout;
