import React, { useEffect, useState, useContext } from "react";
import { useQuery, useMutation } from "urql";
import ReactPaginate from "react-paginate";
import { useModalAction } from "../../context/modalContext";
import { GetSubscribersQuery } from "../../Queries";
import { NavLink } from "react-router-dom";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import { useToasts } from "react-toast-notifications";
import { Link, useParams } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import RenewSubscription from "../Subscribe/RenewSubscriptionButton";
const Subscribers = ({ userId, count, userData }) => {
  const { addToast } = useToasts();
  const params = useParams();
  const { auth } = useContext(AuthContext);

  const slug = params?.slug;
  const { openModal } = useModalAction();
  const [page, setPage] = useState(0);
  const [pageCount, setpageCount] = useState(count / 5);
  const [subscriberResult, executeSubscribersQuery] = useQuery({
    query: GetSubscribersQuery,
    pause: true,
    variables: {
      user_id: userId,
      id: auth?.userId,
      limit: 5,
      offset: page * 5,
    },
  });

  const {
    data: subscriberData,
    fetching: subscriberFetching,
    error: subscribersError,
  } = subscriberResult;

  useEffect(() => {
    executeSubscribersQuery({ requestPolicy: "network-only" });
  }, [page]);

  return (
    <div className=" rounded-md   min-w-64 p-3">
      <div className="h-64 min-w-64 ">
        {subscriberFetching ? (
          <div className="dark:text-gray-300 text-center text-gray-500 ">
            loading...
          </div>
        ) : subscribersError ? (
          <div className="dark:text-gray-300 text-gray-500 ">
            error fetching subscribers
          </div>
        ) : subscriberData?.subscriptions?.length < 1 ? (
          <div className="dark:text-gray-300 text-gray-500 ">
            {auth?.userId === userId ? (
              "No one has subscribed you yet."
            ) : (
              <div>
                {" "}
                No one is Subscribed them yet.
                <div className="w-full flex justify-center">
                  <PrimaryButton
                    text="Subscribe"
                    className="px-8 mx-2 my-4 rounded-full h-10"
                    onClick={() => openModal("SUBSCRIBE_USER_POPUP", userData)}
                  />
                </div>
              </div>
            )}
          </div>
        ) : (
          subscriberData?.subscriptions?.map((h, id) => (
            <NavLink
              key={id}
              to={`/user/${h?.subscriber?.id}/view/collected`}
              className={"bg-purple-400"}
            >
              <div className="flex space-x-3 justify-between align-middle  ">
                <div className="flex my-2 space-x-2">
                  <img
                    src={h?.subscriber?.image}
                    alt={h?.subscriber?.username}
                    className="w-8 h-8 rounded-full object-cover"
                  />
                  <h3 className="cursor-not-allowed dark:text-gray-50 text-gray-800 text-sm  capitalize font-semibold mt-1">
                    {h?.subscriber?.username}{" "}
                  </h3>
                </div>
                <div className="dark:text-gray-300 text-gray-500 text-sm mt-1">
                  {h?.subscriber?.id === auth?.userId ? (
                    <div className=" mt-1 text-center w-48 font-semibold ">
                      You{" "}
                    </div>
                  ) : h?.subscriber?.subscriptionsByUserId_aggregate?.aggregate
                      ?.count < 1 ? (
                    <PrimaryButton
                      text="Subscribe"
                      className="px-4 mx-2 w-48 rounded-full h-10"
                      onClick={() =>
                        openModal("SUBSCRIBE_USER_POPUP", {
                          ...h?.subscriber,
                        })
                      }
                    />
                  ) : (
                    <div className="flex items-center">
                      <RenewSubscription
                        openModal={openModal}
                        className={"rounded-full"}
                        userData={h?.subscriber}
                      />
                    </div>
                  )}
                </div>
              </div>
            </NavLink>
          ))
        )}
      </div>
      {Math.ceil(pageCount) > 0 && subscriberData && (
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={(s) => {
            setPage(s?.selected);
          }}
          activeClassName="dark:text-gray-400 text-gray-600 font-semibold"
          disabledLinkClassName="cursor-not-allowed dark:text-gray-600 text-gray-400"
          disabledClassName="cursor-not-allowed dark:text-gray-600 text-gray-400"
          className="justify-center dark:text-gray-500 text-gray-500  capitalize font-semibold  space-x-4 flex items-center m-2"
          pageRangeDisplayed={5}
          pageCount={Math.ceil(pageCount)}
          previousLabel="< "
          renderOnZeroPageCount={null}
        />
      )}
    </div>
  );
};

export default Subscribers;
