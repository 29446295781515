import React, { useState } from "react";
import { FaArrowRight, FaCheckCircle, FaSpinner } from "react-icons/fa";
import { Button } from "@windmill/react-ui";
import { useModalAction, useModalState } from "../../context/modalContext";
import Collapsible from "../UI/Collapsible";

const SellPopupWallet = () => {
  const [init, setInit] = useState(false);
  const [init1, setInit1] = useState(false);
  const [confirm, setConfirm] = useState(false);

  return (
    <div>
      <h3 className="text-lg font-semibold dark:text-white mb-4">
        Complete you listing
      </h3>
      <p className="dark:text-gray-400">
        Your wallet balance is below 0.05 ETH. The next steps require small
        transaction fees, so you may have to deposit additional funds to
        complete them.
      </p>
      <div className="flex flex-col space-y-6 mt-6">
        <Collapsible
          Icon={FaCheckCircle}
          title="Initialize your wallet"
          open={init}
          setOpen={setInit}
        >
          <p className="dark:text-white">
            Your wallet balance is below 0.05 ETH. The next steps require small
            transaction fees, so you may have to deposit additional funds to
            complete them.
          </p>
        </Collapsible>

        <Collapsible
          Icon={FaCheckCircle}
          title="Initialize your wallet"
          open={init1}
          setOpen={setInit1}
        >
          <p className="dark:text-white">
            Your wallet balance is below 0.05 ETH. The next steps require small
            transaction fees, so you may have to deposit additional funds to
            complete them.
          </p>
        </Collapsible>

        <Collapsible
          Icon={FaSpinner}
          iconClass="animate-spin"
          title="Confirm listing"
          open={confirm}
          setOpen={setConfirm}
        >
          <p className="dark:text-white">
            Accept the signature request in your wallet and wait for your
            listing to process.
          </p>
        </Collapsible>
      </div>
    </div>
  );
};

export default SellPopupWallet;
