import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import PrimaryButton from "../Buttons/PrimaryButton";
import { useModalAction, useModalState } from "../../context/modalContext";

function SuccessPopup() {
  const { openModal, closeModal } = useModalAction();
  const { data } = useModalState();

  return (
    <div className="text-center">
      <FaCheckCircle className="text-green-400 text-6xl mx-auto mb-6" />
      <h4 className="text-3xl font-semibold mb-10 dark:text-white">
        {data?.text ? data?.text : "Purchase Successful"}
      </h4>
      <PrimaryButton
        onClick={(e) => {
          e.preventDefault();
          closeModal("SUCCESS_VIEW");
        }}
        text="Okay"
        className="w-1/2 py-3 mx-auto"
      />
    </div>
  );
}

export default SuccessPopup;
