import React, { useEffect, useState, useContext } from "react";
import { FaArrowRight } from "react-icons/fa";
import { Button } from "@windmill/react-ui";
import { useToasts } from "react-toast-notifications";
import { useModalAction, useModalState } from "../../context/modalContext";
import { baseAPIUrl } from "../../config";
import { listToBid } from "../../contracts";
import useFetch, { CachePolicies } from "use-http";
import { AuthContext } from "../../context/AuthContext";

const PlaceBidSellPopup = () => {
  const { auth } = useContext(AuthContext);
  const { post, response, loading, error } = useFetch(baseAPIUrl, {
    headers: { Authorization: `Bearer ${auth.token}` },
    cachePolicy: "no-cache",
  });
  const [terms, setTerms] = useState(false);
  const { data } = useModalState();
  const { openModal, closeModal } = useModalAction();
  const [waiting, setWaiting] = useState(false);

  const { addToast } = useToasts();
  const { history } = data;

  const startAuction = async () => {
    setWaiting(true);
    try {
      if (
        typeof window.ethereum !== "undefined" &&
        window.ethereum.networkVersion === process.env.REACT_APP_CHAINID
      ) {
        const contractInteraction = await listToBid(
          window?.ethereum,
          data?.token_id,
          data?.minBid,
          data?.days
        );
        if (contractInteraction?.tokenId) {
          //update the table
          console.log(data);
          const updateNft = await post("nft/create_auction", {
            bid_min_price: data?.minBid,
            bid_close_time: data?.time,
            type: data?.type ?? "bid",
            id: data?.id,
          });
          if (updateNft?.message === "success") {
            closeModal("PLACE_BID_SELL_POPUP");
            history("/profile/view/more");
          } else {
            throw "Couldn't create auction";
          }
        } else {
          throw contractInteraction?.error;
        }
      } else {
        throw "Wrong network! Please connect to the correct network.";
      }
    } catch (error) {
      // console.log(error);
      if (error.toString().includes("unknown account #0")) {
        addToast("Please connect to wallet first!", { appearance: "error" });
      } else {
        addToast(error?.reason || error, { appearance: "error" });
      }
    }
    setWaiting(false);
  };

  return (
    <div>
      <h3 className="text-lg font-semibold dark:text-white">
        Complete your listing
      </h3>

      <div className="mt-4">
        <h5 className="text-lg mb-4 font-medium">Item</h5>
        <div className="flex items-center">
          <img src={data?.asset} alt="Item image" className="w-16 h-16" />
          <div className="nftInfo ml-4">
            <h3 className="text-xl font-medium dark:text-white">
              {data?.name}
            </h3>
            <h6 className="mb-2 dark:text-white">{data?.collection?.name}</h6>
          </div>
        </div>
      </div>

      <div className="mt-4">
        <h5 className="text-lg mb-4 font-medium">Amount</h5>
        <div className="flex items-center">
          <span className="text-lg dark:text-whtie">Minimum Bid</span>
          <h4 className="text-lg font-medium mb-2 ml-auto">
            {data?.minBid} ETH
          </h4>
          <p className="text-sm ml-2">($ {data?.price})</p>
        </div>
        <div className="flex items-center justify-between">
          <span className="text-lg dark:text-whtie">Auction duration </span>
          <h4 className="text-lg font-medium mb-2 ml-auto">
            {data?.days} days
          </h4>
        </div>
      </div>

      <div className="flex items-center mt-4">
        <input
          type="checkbox"
          className="cursor-pointer"
          checked={terms}
          onChange={() => setTerms((prevState) => !prevState)}
        />
        <span className="text-xs text-gray-600 ml-2">
          By checking this box, I agree to Gruve’s Terms of Service
        </span>
      </div>
      <div className="text-right mt-6">
        <Button
          disabled={!terms || waiting}
          onClick={() => {
            startAuction();
          }}
          className="bg-purple-600 ml-auto py-3 px-12 rounded-lg  text-white flex items-center main-button"
        >
          {waiting ? (
            <span>Loading..</span>
          ) : (
            <>
              <span>Confirm Listing</span>
              <FaArrowRight className="ml-6" />
            </>
          )}
        </Button>
      </div>
    </div>
  );
};

export default PlaceBidSellPopup;
