import React, { useEffect, useState } from "react";
import PrimaryButton from "../Buttons/PrimaryButton";

function RenewSubscription({ userData, className, openModal }) {
  return (
    <PrimaryButton
      text="Renew"
      className={`${className} px-4 mx-2  w-56 py-1 h-12`}
      onClick={(e) => {
        e.preventDefault();
        openModal("RENEW_SUBSCRIPTION", { ...userData });
      }}
    />
  );
}

export default RenewSubscription;
