import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./assets/css/tailwind.output.css";
import "./assets/css/styles.css";
import App from "./App";
import { SidebarProvider } from "./context/SidebarContext";
import ThemedSuspense from "./components/ThemedSuspense";
import { Windmill } from "@windmill/react-ui";
import * as serviceWorker from "./serviceWorker";
import AuthProvider from "./context/AuthContext";
import { ToastProvider } from "react-toast-notifications";
import myTheme from "./theme/myTheme";
import { ThemeProvider } from "./context/ThemeContext";
import { WagmiContext, WagmiProvider } from "./context/WagmiContext";
// if (process.env.NODE_ENV !=./context/WagmiContext
//   const axe = require('react-axe')
//   axe(React, ReactDOM, 1000)
// }
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ThemeProvider>
    <ToastProvider autoDismiss>
      <WagmiProvider>
        <AuthProvider>
          <SidebarProvider>
            <Suspense fallback={<ThemedSuspense />}>
              <Windmill usePreferences theme={myTheme}>
                <App />
              </Windmill>
            </Suspense>
          </SidebarProvider>
        </AuthProvider>
      </WagmiProvider>
    </ToastProvider>
  </ThemeProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
