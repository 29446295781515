import React from "react";
import { Modal, ModalHeader, ModalBody } from "@windmill/react-ui";

const ModalUi = ({ children, open, onClose, title }) => {
  return (
    <Modal isOpen={open} onClose={onClose}>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>{children}</ModalBody>
    </Modal>
  );
};

export default ModalUi;
