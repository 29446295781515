import React, { createContext, useState, useEffect } from "react";
import { HASURA_ENDPOINT_DEV } from "../config";
import {
  createClient,
  Provider,
  defaultExchanges,
  subscriptionExchange,
} from "urql";
import { SubscriptionClient } from "subscriptions-transport-ws";
import "@rainbow-me/rainbowkit/styles.css";
import { baseAPIUrl } from "../config";
import { useAccount, useDisconnect } from "wagmi";
import { useToasts } from "react-toast-notifications";
export const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const { addToast } = useToasts();
  const [status, setStatus] = useState("loading..");
  const { disconnect } = useDisconnect();
  const { address } = useAccount({
    onConnect({ address, connector, isReconnected }) {
      setStatus("connected");
      if (!isReconnected) {
        connectWallet(address);
      }
    },
    onDisconnect() {
      setStatus("disconnected");
      console.log("Disconnected");
      disconnectWallet();
    },
  });
  const [auth, setAuth] = useState({
    loading: true,
    data: {
      token: undefined,
      walletId: undefined,
    },
  });
  let headers = {};

  if (auth?.token) {
    headers = {
      Authorization: `Bearer ${auth?.token}`,
    };
  }

  const subscriptionClient = new SubscriptionClient(
    `wss://${HASURA_ENDPOINT_DEV}`,
    {
      reconnect: false,
      connectionParams: {
        headers,
      },
    }
  );

  const client = createClient({
    url: process.env.HASURA_ENDPOINT || `https://${HASURA_ENDPOINT_DEV}`,
    exchanges: [
      ...defaultExchanges,
      subscriptionExchange({
        forwardSubscription(operation) {
          return subscriptionClient.request(operation);
        },
      }),
    ],
    requestPolicy: "network-only",
    // fetch: fetch,
    fetchOptions: () => {
      if (auth?.token === null) {
        return true;
      }
      return {
        headers,
      };
    },
  });

  const disconnectWallet = () => {
    setAuth({
      ...auth,
      token: undefined,
      walletId: undefined,
      userId: undefined,
    });
    localStorage.removeItem("auth");
    localStorage.removeItem("token");
    localStorage.removeItem("walletId");
    localStorage.removeItem("userId");
  };

  const setAuthData = (data) => {
    setAuth({
      ...auth,
      token: data.token,
      walletId: data.walletId,
      userId: data.userId,
    });
    localStorage.setItem("token", data.token);
    localStorage.setItem("walletId", data.walletId);
    localStorage.setItem("userId", data.userId);
  };
  const connectWallet = async (address) => {
    try {
      let body = { wallet_id: address };
      let res = await fetch(`${baseAPIUrl}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const temp = await res.json();
      console.log(temp);
      if (temp?.data?.blocked) {
        disconnect();
        addToast(
          "You account is blocked. Please contact support for more information.",
          { appearance: "error" }
        );
      } else if (temp?.message === "error") {
        addToast("Login Error", { appearance: "error" });
        disconnect();
      } else {
        setAuthData(temp?.data);
        addToast("Successfully logged in", { appearance: "success" });
        setStatus("connected");
      }
    } catch (err) {
      addToast(err, { appearance: "warning" });
      setStatus("error");
    }
  };

  useEffect(() => {
    let data = localStorage?.getItem("auth");
    let token = localStorage?.getItem("token");
    let userId = localStorage?.getItem("userId");
    if (token && address && userId) {
      setAuth({
        loading: false,
        data: data,
        token: token,
        walletId: address,
        userId: userId,
      });
    } else {
      setAuth({
        loading: false,
        data: JSON.parse(data),
        token: undefined,
        walletId: undefined,
        userId: undefined,
      });
    }
  }, []);

  return (
    <AuthContext.Provider value={{ auth, status }}>
      <Provider value={client}>{children}</Provider>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
