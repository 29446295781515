import React, { useEffect, useState, useRef, useMemo } from "react";
import { useQuery } from "urql";
import { HeaderSearchQuery } from "../Queries";
import { Input, Dropdown, DropdownItem } from "@windmill/react-ui";
import { NavLink } from "react-router-dom";
import debounce from "lodash.debounce";

const SearchInput = ({ theme }) => {
  const [searchValue, setSearchValue] = useState("");
  const [searchMenu, setSearchMenu] = useState(true);
  const [searchQuery, executeSearch] = useQuery({
    query: HeaderSearchQuery,
    variables: {
      search: `%${searchValue}%`,
    },
    pause: true,
  });
  const inputRef = useRef(null);

  const { data: searchData, fetching: searchFetching } = searchQuery;

  useEffect(() => {
    if (searchValue?.trim() !== "") {
      setSearchMenu(true);
      executeSearch({ requestPolicy: "network-only" });
    } else {
      setSearchMenu(false);
    }
  }, [searchValue]);
  const changeHandler = async (e) => {
    setSearchValue(e);
  };
  const debouncedEventHandler = debounce((e) => {
    changeHandler(e);
  }, 1000);
  return (
    <>
      <input
        key={"search"}
        className={`pl-12 w-full  py-3 searchbar ${
          theme === "dark"
            ? "text-gray-100 bg-gray-900"
            : "text-gray-900 bg-gray-100"
        } border-none rounded-lg overflow-hidden `}
        placeholder="Search"
        aria-label="Search"
        value={searchValue}
        // onChange={(e) => {
        //   debouncedEventHandler(e.target.value);
        // }}
        onChange={(e) => {
          changeHandler(e.target.value);
        }}
      />
      <button hidden={true} id="searchButton">
        Search
      </button>
      <Dropdown
        key={"suggestion"}
        className="w-full z-40"
        isOpen={searchMenu}
        onClose={() => setSearchMenu(false)}
      >
        {searchFetching ? (
          <DropdownItem tag="span">Loading...</DropdownItem>
        ) : searchData !== undefined && searchData !== null && searchData ? (
          Object.keys(searchData).map((item, i) => (
            <div className=" flex-col" key={i}>
              {" "}
              <div className="">
                {" "}
                <div className="text-gray-400 mx-2 mt-4 mb-2">{item}</div>
                {searchData?.[item]?.length > 0 ? (
                  searchData[item]?.map((subitem) => (
                    <DropdownItem key={subitem?.id}>
                      <NavLink to={`/${item}/${subitem?.id}`} item>
                        {subitem?.name || subitem?.username}{" "}
                      </NavLink>
                    </DropdownItem>
                  ))
                ) : (
                  <span className="text-sm mx-2">No Matching Found</span>
                )}
              </div>
            </div>
          ))
        ) : (
          <DropdownItem tag="span">No Items Found</DropdownItem>
        )}
      </Dropdown>
    </>
  );
};
export default SearchInput;
