import { lazy } from "react";
// use lazy for better code splitting, a.k.a. load faster
const Home = lazy(() => import("../pages/Home"));
const Explore = lazy(() => import("../pages/Explore"));
const SingleNft = lazy(() => import("../pages/SingleNft"));
const Blogs = lazy(() => import("../pages/blogs"));
const BlogDetails = lazy(() => import("../pages/blogs/BlogDetails"));
const Subscription = lazy(() => import("../pages/Subscription"));
const CreateNft = lazy(() => import("../pages/CreateNft"));
const Sell = lazy(() => import("../pages/Sell"));
const Profile = lazy(() => import("../pages/Profile"));
const Settings = lazy(() => import("../pages/Profile/Settings"));
const Page404 = lazy(() => import("../pages/404"));
const Feed = lazy(() => import("../pages/Feed"));
const Create = lazy(() => import("../pages/Create"));
const CreateCollection = lazy(() => import("../pages/CreateCollection"));
const Collection = lazy(() => import("../pages/Collection"));
/**
 * ⚠ These are internal routes!
 * They will be rendered inside the app, using the default `containers/Layout`.
 * If you want to add a route to, let's say, a landing page, you should add
 * it to the `App`'s router, exactly like `Login`, `CreateAccount` and other pages
 * are routed.
 *
 * If you're looking for the links rendered in the SidebarContent, go to
 * `routes/sidebar.js`
 */
export const routes = [
  {
    path: "/", // the url
    component: Home, // view rendered
  },
  {
    path: "/explore",
    component: Explore,
  },
  {
    path: "/search/:searchValue",
    component: Explore,
  },
  {
    path: "/search/",
    component: Explore,
  },
  {
    path: "/categories/:category/search/:searchValue",
    component: Explore,
  },
  {
    path: "/categories/:category/explore",
    component: Explore,
  },
  {
    path: "/nft/:slug",
    component: SingleNft,
  },
  {
    path: "/blogs",
    component: Blogs,
  },
  {
    path: "/blogs/:slug",
    component: BlogDetails,
  },
  {
    path: "/feeds",
    component: Feed,
  },
  // {
  //   path: "/subscriptions",
  //   component: Subscription,
  // },
  // {
  //   path: "/create-new-nft",
  //   component: CreateNft,
  // },

  // {
  //   path: "/create-new-nft/:slug",
  //   component: NewNft,
  // },
  // {
  //   path: "/profile",
  //   component: Profile,
  // },
  // {
  //   path: "/profile/settings",
  //   component: Settings,
  // },

  {
    path: "/user/:slug/view/:type",
    component: Profile,
  },
  {
    path: "/user/:slug",
    component: Profile,
  },
  {
    path: "/404",
    component: Page404,
  },

  {
    path: "/*",
    component: Page404,
  },
  { path: "/collection/:id", component: Collection },
];

export const privateRoutes = [
  {
    path: "/profile/view/:type",
    component: Profile,
  },

  {
    path: "/create/create-new-nft",
    component: CreateNft,
  },
  {
    path: "/create",
    component: Create,
  },
  {
    path: "/create/create-new-collection",
    component: CreateCollection,
  },
  {
    path: "/create/create-new-nft/:slug",
    component: SingleNft,
  },
  {
    path: "/nft/:slug/sell",
    component: Sell,
  },
  {
    path: "/profile/settings",
    component: Settings,
  },
  {
    path: "/subscriptions",
    component: Subscription,
  },
];
