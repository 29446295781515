import React, { useState, useContext } from "react";
import PrimaryButton from "../Buttons/PrimaryButton";
import CustomSelectBox from "../Form/CustomSelectBox";
import { useMutation } from "urql";
import { AuthContext } from "../../context/AuthContext";
import { CreateFlagMutation } from "../../Queries";
import { useToasts } from "react-toast-notifications";
import { useModalState, useModalAction } from "../../context/modalContext";

const options = [
  {
    name: "Fake collection or possible scam",
    value: 1,
  },
  {
    name: "Explicit and sensitive content",
    value: 2,
  },
  {
    name: "Spam",
    value: 3,
  },
  {
    name: "Might be stolen",
    value: 4,
  },
];

function ReportPopup() {
  const [selectedReport, setReport] = useState();
  const { data } = useModalState();
  const { openModal, closeModal } = useModalAction();
  const { auth } = useContext(AuthContext);
  const [{ flagMutationFetching }, executeFlagMutation] =
    useMutation(CreateFlagMutation);
  const { addToast } = useToasts();
  console.log(data, "data");
  const handleReport = async () => {
    try {
      if (!auth.userId)
        addToast("Please login or create an account before reporting", {
          appearance: "warning",
        });
      if (selectedReport && auth.userId) {
        const result = await executeFlagMutation({
          object: {
            nft_id: data?.id,
            user_id: auth?.userId,
            reason: selectedReport.name,
          },
        });
        if (result?.data) {
          addToast("Succesfully reported.", { appearance: "success" });
        } else {
          throw result?.error;
        }
      }
      closeModal("REPORT_POPUP");
    } catch (error) {
      addToast(error.message, { appearance: "error" });
    }
  };
  return (
    <div>
      <h3 className="text-lg font-semibold dark:text-white">
        Report this item
      </h3>

      <div className="mt-4">
        <CustomSelectBox
          label="I think this item is.."
          options={options}
          selectedItem={selectedReport}
          setSelectedItem={setReport}
          className="border border-gray-500"
          placeholder="Select a reason"
        />
      </div>

      <div className="text-right mt-8">
        <PrimaryButton
          text="Report"
          className="shadow-lg ml-auto px-10"
          onClick={handleReport}
        />
      </div>
    </div>
  );
}

export default ReportPopup;
